import { createSlice } from '@reduxjs/toolkit';

interface IState {
    userDetails: {
        id: string;
        orgs: { isDefault: boolean; code: string }[];
        name: string;
        picture?: string;
    };
    token: string;
}

const initialState: IState = {
    userDetails: {
        id: '',
        orgs: [],
        name: ''
    },
    token: ''
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateToken: (state, action) => {
            state.token = action.payload;
        },
        updateUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        clearStore: (state) => {
            state.userDetails = {} as IState['userDetails'];
            state.token = '';
        }
    }
});

export const { updateToken, updateUserDetails, clearStore } = userSlice.actions;

export default userSlice.reducer;
