import { createSlice } from '@reduxjs/toolkit';

interface IState {
    isGpt4Enabled: boolean;
    fileChunkSize: number;
    summaryLength: number;
    isHubspotEnabled: boolean;
    enableContacts: boolean;
    enableAdvancedPersonalizationCampaign: boolean;
}

const initialState: IState = {
    isGpt4Enabled: true,
    fileChunkSize: 10000,
    summaryLength: 300,
    isHubspotEnabled: false,
    enableContacts: false,
    enableAdvancedPersonalizationCampaign: false
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateConfig: (state: IState, action) => {
            state.isGpt4Enabled = action.payload.isGpt4Enabled;
            state.fileChunkSize = action.payload.fileChunkSize;
            state.summaryLength = action.payload.summaryLength;
            state.isHubspotEnabled = action.payload.isHubspotEnabled;
            state.enableContacts = action.payload.enableContacts;
            state.enableAdvancedPersonalizationCampaign =
                action.payload.enableAdvancedPersonalizationCampaign;
        }
    }
});

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
