import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

interface IProps {
    title: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    id?: string;
    icon?: string;
    className?: string;
    gradient?: boolean;
}

const CustomButton = ({
    className = '',
    title = '',
    onClick,
    id = '',
    icon,
    gradient = false
}: IProps) => {
    return (
        <h1
            id={id}
            className={cx(styles.container, className, { [styles.gradient]: gradient })}
            onClick={onClick}
        >
            {icon && <div className={icon} />}
            <h1>{title}</h1>
        </h1>
    );
};

export default React.memo(CustomButton);
