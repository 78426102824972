import React, { useState } from 'react';
import cx from 'classnames';

// widgets
import ContactsTable from 'src/views/Private/Contacts/widgets/ContactsTable';

// constants
import { FILTERS } from 'src/views/Private/Contacts/widgets/Filters/constants';

// defs
import { GROUP_FILTERS } from 'src/shared/constants';
import type { IContacts } from 'src/defs';

interface IProps {
    handleSelectContacts: (contacts: IContacts[], groupBy: string) => void;
    groupBy?: string;
    selectedContacts?: IContacts[];
    error?: string;
}

const Contacts = ({ groupBy, error, selectedContacts, handleSelectContacts }: IProps) => {
    const [campaignContacts, setCampaignContacts] = useState<IContacts[]>(selectedContacts || []);

    const handleCampaignContacts = (contacts: IContacts[], groupBy: string) => {
        setCampaignContacts([...contacts]);
        handleSelectContacts([...contacts], groupBy);
    };

    const selectedGroup = GROUP_FILTERS.find((filter) => filter.value === groupBy);

    return (
        <div className={cx('relative', 'grid')}>
            <ContactsTable
                selectedFilters={FILTERS}
                listLength={500}
                isCampaign
                handleCampaignContacts={handleCampaignContacts}
                campaignContacts={campaignContacts}
                groupBy={selectedGroup}
                error={error}
            />
        </div>
    );
};

export default React.memo(Contacts);
