export const FILTERS = [
    { label: 'First Name', value: 'firstName' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'Email', value: 'email' },
    { label: 'Job Title', value: 'jobTitle' },
    { label: 'Company Name', value: 'companyName' },
    // { label: 'Company Size', value: 'companySize' },
    { label: 'Industry', value: 'industry' },
    // { label: 'Website URL', value: 'websiteURL' },
    // { label: 'Country', value: 'country' },
    // { label: 'City', value: 'city' },
    // { label: 'State/Region', value: 'stateRegion' },
    // { label: 'Postal Code', value: 'postalCode' },
    // { label: 'Address 1', value: 'address1' },
    // { label: 'Address 2', value: 'address2' },
    // { label: 'Life Cycle Stage', value: 'lifecycleStage' },
    // { label: 'Last Contacted', value: 'lastContacted' },
    // { label: 'Time in Stage', value: 'timeInStage' },
    // { label: 'Page Views', value: 'numPageViews' },
    // { label: 'Website Visits', value: 'numWebsiteVisits' },
    // { label: 'Form Submissions', value: 'numFormSubmissions' },
    // { label: 'Last Activity Date', value: 'lastActivityDate' },
    // { label: 'Original Source', value: 'originalSource' },
    // { label: 'Original Source Campaign', value: 'originalSourceCampaign' },
    // { label: 'Origin Source Medium', value: 'originalSourceMedium' },
    // { label: 'Created Date', value: 'createdDate' },
    // { label: 'Email Subscription Status', value: 'emailSubscriptionStatus' },
    // { label: 'Do not Email', value: 'doNotEmail' },
    // { label: 'Last Email Send Date', value: 'lastEmailSendDate' },
    // { label: 'Last Email Click Date', value: 'lastEmailClickDate' },
    // { label: 'Annual Revenue', value: 'annualRevenue' },
    // { label: 'Company Industry', value: 'companyIndustry' },
    // { label: 'Seniority Level', value: 'seniorityLevel' },
    // { label: 'Recent Deal Amount', value: 'recentDealAmount' },
    // { label: 'Last Modified Date', value: 'lastModifiedDate' },
    // { label: 'Data Source', value: 'dataSource' },
    // { label: 'Data Enrichment Status', value: 'dataEnrichmentStatus' },
    // { label: 'Record Owner', value: 'recordOwner' },
    // { label: 'GDPR Checklist', value: 'gdprChecklist' },
    // { label: 'Contact Interaction Notes', value: 'contactInteractionNotes' },
    // { label: 'Comments/Questions', value: 'commentsOrQuestions' },
    // { label: 'Klout Score', value: 'kloutScore' },
    // { label: 'Linkedin Connections', value: 'linkedInConnections' },
    // { label: 'Twitter Followers', value: 'twitterFollowers' },
    { label: 'Linkedin BIO', value: 'linkedin_bio' },
    // { label: 'Enriched Data', value: 'enrichedData' },
    { label: 'Pain Points', value: 'painPoints' },
    { label: 'Industry Trends', value: 'industryTrends' },
    { label: 'Targeting Strategy1', value: 'targetingStrategy1' },
    { label: 'Targeting Strategy2', value: 'targetingStrategy2' },
    { label: 'Status', value: 'status' }
];
