//helpers
import {
    generateBdrCadencePrompt,
    generateBdrEmailsPrompt,
    generatePersonalizedPrompt,
    generatePrompt,
    getChoiceCount,
    getTokenCount,
    getWordCount
} from 'src/shared/utils/helpers';

//defs
import type { IAudience, IPersona } from 'src/defs';
import type { IContacts } from 'src/defs';

//constants
import { CLAP_SESSION } from 'src/shared/constants';

const maxAttempts = 3;

interface ICampaignGenerator {
    key: number;
    documentTypes: [];
    summaryInfo: string;
    persona: IPersona;
    content: string;
    toneRegister: string;
    audience: IAudience;
    maxWords: [];
    subType: string;
    regCount?: number;
    tokenCount?: number;
    mType?: string | number;
    getResults: any;
    instructions?: string;
    seoKeywords?: string;
    isRegenerate?: boolean;
    personaFilter?: string;
    contentFilter?: string;
    emotionalFilter?: string;
    contact?: IContacts;
}

export const generateBdrCadenceData = async ({
    key,
    documentTypes,
    summaryInfo,
    persona,
    audience,
    toneRegister,
    content,
    maxWords,
    subType,
    regCount,
    tokenCount,
    mType,
    getResults,
    personaFilter,
    contentFilter,
    emotionalFilter,
    contact,
    instructions
}: ICampaignGenerator) => {
    let bdrCadenceCounter = [0, 1, 2, 3, 4, 5, 6];
    if (
        ['bdr_customTemplate_1', 'bdr_customTemplate_2', 'bdr_customTemplate_3'].includes(subType)
    ) {
        bdrCadenceCounter = [0];
    } else if (subType === 'bdr_customCombinedTemplates') {
        bdrCadenceCounter = [0, 1, 2];
    }
    if (key >= 0) {
        bdrCadenceCounter = [key];
    }
    let bdrCadenceRes = [];

    // for (const key of bdrCadenceCounter) {
    bdrCadenceRes = bdrCadenceCounter.map(async (key) => {
        let prompt = generateBdrCadencePrompt({
            documentTypes,
            summaryInfo,
            persona,
            contentType: content,
            toneRegister,
            audience,
            maxWords,
            key,
            subType,
            personaFilter,
            contentFilter,
            emotionalFilter,
            contact,
            instructions
        });

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
            try {
                let res = await getResults({
                    message: prompt,
                    count: getChoiceCount(content),
                    max_tokens: getTokenCount(content),
                    fileSession: sessionStorage.getItem(CLAP_SESSION),
                    words: getWordCount(content),
                    content,
                    persona:
                        persona?.name !== 'none'
                            ? `${persona?.name} | version: ${persona?.version}`
                            : '',
                    filters: `register: ${toneRegister} | audience: ${audience}`,
                    type: 1, //flag to save the processed prompt
                    regenerateCount: regCount,
                    tokenCount,
                    mType
                }).unwrap();
                // bdrCadenceRes.push({
                //     choices: res?.choices
                // });
                return { choices: res?.choices };
                // break;
            } catch (error: any) {
                if (attempt < maxAttempts) {
                    console.error(`Attempt ${attempt} failed. Retrying...`);
                } else {
                    throw error?.data?.message; // If maxAttempts is reached, throw the error
                }
            }
        }
    });

    bdrCadenceRes = await Promise.all([...bdrCadenceRes]);

    if (bdrCadenceRes && bdrCadenceRes.length) {
        bdrCadenceRes = bdrCadenceRes.flat();
        if (bdrCadenceRes.length === 1 && key) {
            let res = [];
            res[key] = bdrCadenceRes[0]?.choices[0];
            return {
                choices: res,
                type: content,
                accepted: true
            };
        }
        return {
            choices: [...bdrCadenceRes.map((choice) => choice?.choices[0])],
            type: content,
            accepted: true
        };
    }
    return [];
};

export const generateBdrEmailData = async ({
    key,
    documentTypes,
    summaryInfo,
    persona,
    audience,
    toneRegister,
    content,
    maxWords,
    regCount,
    tokenCount,
    mType,
    instructions,
    getResults
}: ICampaignGenerator) => {
    let counter = [0, 1, 2];

    if (key >= 0) {
        counter = [key];
    }

    let results = [];
    for (const key of counter) {
        let message = generateBdrEmailsPrompt(
            documentTypes,
            summaryInfo,
            persona,
            content,
            toneRegister,
            audience,
            maxWords,
            instructions,
            key
        );
        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
            try {
                let res = await getResults({
                    message,
                    count: getChoiceCount(content),
                    max_tokens: getTokenCount(content),
                    fileSession: sessionStorage.getItem(CLAP_SESSION),
                    words: getWordCount(content),
                    content,
                    persona:
                        persona?.name !== 'none'
                            ? `${persona?.name} | version: ${persona?.version}`
                            : '',
                    filters: `register: ${toneRegister} | audience: ${audience}`,
                    type: 1, //flag to save the processed prompt
                    regenerateCount: regCount,
                    tokenCount,
                    mType
                }).unwrap();
                results.push({
                    choices: res?.choices
                });
                break;
            } catch (error: any) {
                if (attempt < maxAttempts) {
                    console.error(`Attempt ${attempt} failed. Retrying...`);
                } else {
                    throw error?.data?.message; // If maxAttempts is reached, throw the error
                }
            }
        }
    }

    let bdrChoices = results;
    if (bdrChoices && bdrChoices.length) {
        bdrChoices = bdrChoices.flat();
        if (bdrChoices.length === 1 && key) {
            let res = [];
            res[key] = bdrChoices[0]?.choices[0];
            return {
                choices: res,
                type: content,
                accepted: true
            };
        }
        return {
            choices: [...bdrChoices.map((choice) => choice?.choices[0])],
            type: content,
            accepted: true
        };
    }
    return [];
};

export const generateCampaignData = async ({
    documentTypes,
    summaryInfo,
    persona,
    content,
    audience,
    maxWords,
    tokenCount,
    regCount,
    mType,
    toneRegister,
    instructions,
    seoKeywords,
    getResults,
    isRegenerate
}: ICampaignGenerator) => {
    let instruct =
        instructions + ` Use these keywords to make content SEO friendly: ${seoKeywords}`;

    let message = generatePrompt(
        documentTypes,
        summaryInfo,
        persona,
        content,
        toneRegister,
        audience,
        maxWords,
        instruct
    );
    let response = {};
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
        try {
            let res = await getResults({
                message,
                count: getChoiceCount(content),
                max_tokens: getTokenCount(content),
                fileSession: sessionStorage.getItem(CLAP_SESSION),
                words: getWordCount(content),
                content,
                persona:
                    persona?.name !== 'none'
                        ? `${persona?.name} | version: ${persona?.version}`
                        : '',
                filters: `register: ${toneRegister} | audience: ${audience}`,
                type: 1, //flag to save the processed prompt
                regenerateCount: regCount,
                tokenCount,
                mType,
                isRegenerate
            }).unwrap();
            response = {
                choices: res?.choices,
                type: content,
                accepted: true,
                accepted_idx: 0
            };
            break;
        } catch (error: any) {
            if (attempt < maxAttempts) {
                console.error(`Attempt ${attempt} failed. Retrying...`);
            } else {
                throw error?.data?.message; // If maxAttempts is reached, throw the error
            }
        }
    }
    return response;
};

export const generatePersonalizedData = async ({
    key,
    documentTypes,
    summaryInfo,
    persona,
    audience,
    toneRegister,
    content,
    maxWords,
    subType,
    regCount,
    tokenCount,
    mType,
    getResults
}: ICampaignGenerator) => {
    let personalizedCounter = [0, 1, 2];
    if (
        ['bdr_customTemplate_1', 'bdr_customTemplate_2', 'bdr_customTemplate_3'].includes(subType)
    ) {
        personalizedCounter = [0];
    }

    if (key >= 0) {
        personalizedCounter = [key];
    }
    let personalizedRes = [];

    // for (const key of personalizedCounter) {
    personalizedRes = personalizedCounter.map(async (key) => {
        let prompt = generatePersonalizedPrompt(
            documentTypes,
            summaryInfo,
            persona,
            content,
            toneRegister,
            audience,
            maxWords,
            key,
            subType
        );

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
            try {
                let res = await getResults({
                    message: prompt,
                    count: getChoiceCount(content),
                    max_tokens: getTokenCount(content),
                    fileSession: sessionStorage.getItem(CLAP_SESSION),
                    words: getWordCount(content),
                    content,
                    persona:
                        persona?.name !== 'none'
                            ? `${persona?.name} | version: ${persona?.version}`
                            : '',
                    filters: `register: ${toneRegister} | audience: ${audience}`,
                    type: 1, //flag to save the processed prompt
                    regenerateCount: regCount,
                    tokenCount,
                    mType
                }).unwrap();
                // personalizedRes.push({
                //     choices: res?.choices
                // });
                return { choices: res?.choices };
                // break;
            } catch (error: any) {
                if (attempt < maxAttempts) {
                    console.error(`Attempt ${attempt} failed. Retrying...`);
                } else {
                    throw error?.data?.message; // If maxAttempts is reached, throw the error
                }
            }
        }
    });

    personalizedRes = await Promise.all([...personalizedRes]);

    if (personalizedRes && personalizedRes.length) {
        personalizedRes = personalizedRes.flat();
        if (personalizedRes.length === 1 && key) {
            let res = [];
            res[key] = personalizedRes[0]?.choices[0];
            return {
                choices: res,
                type: content,
                accepted: true
            };
        }
        return {
            choices: [...personalizedRes.map((choice) => choice?.choices[0])],
            type: content,
            accepted: true
        };
    }
    return [];
};
