import React from 'react';
import cx from 'classnames';

//constants
import {
    CONTENT_FILTER,
    EMOTIONAL_FILTER,
    FILTERS,
    PERSONA_FILTER,
    TONE_FILTER
} from 'src/views/Private/AdvancedCampaign/constants';
import { ICON_TRIANGLE_RIGHT } from 'src/shared/constants/icons';

//helpers
import { format } from 'src/shared/utils';
import { IContentFormData } from 'src/defs';

interface IProps {
    data: IContentFormData;
}

const BottomBar = ({ data }: IProps) => {
    return (
        <div
            className={cx(
                'absolute bottom-0 z-20',
                'bg-slate-200',
                'py-1 px-2',
                'flex flex-1 items-center justify-between',
                'rounded',
                'w-[calc(72%_-_6px)]'
            )}
        >
            <div className={cx('flex items-center')}>
                {FILTERS}
                <span className={cx(ICON_TRIANGLE_RIGHT, 'mt-0.5 mx-1', 'text-slate-500')} />
            </div>
            <div className={cx('mx-8 capitalize')}>
                {format(TONE_FILTER, data.toneRegister || '--')}
            </div>
            <div className={cx('mx-8')}>
                {format(EMOTIONAL_FILTER, data.emotionalFilter || '--')}
            </div>
            <div className={cx('mx-8')}>{format(CONTENT_FILTER, data.contentFilter || '--')}</div>
            <div className={cx('mx-8')}>{format(PERSONA_FILTER, data.personaFilter || '--')}</div>
        </div>
    );
};

export default React.memo(BottomBar);
