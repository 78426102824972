import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

//defs
import type { IAudience, IContentForm, IContentFormData, IPersona, IPreset } from 'src/defs';
import type { MenuPlacement } from 'react-select';
import type { IContacts } from 'src/defs';

//constants
import { PERSONA } from 'src/shared/constants';
import { STRINGS } from 'src/shared/strings';
import { CONTENT_FILE_TYPES } from 'src/shared/constants';
import { BDR_CADENCE_OPTIONS } from '../../constants';

//helpers
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';
import { useLocation } from 'react-router-dom';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { useDispatch } from 'react-redux';
import { validateData } from 'src/views/Private/AdvancedCampaign/helpers';

//widgets
import {
    CustomButton,
    CustomInput,
    CustomInputSeparator,
    CustomTextArea,
    FileUpload
} from 'src/widgets';
import CheckBlock from './widgets/CheckBlock';
import AddPersona from './widgets/AddPersona/AddPersona';
import AddAudience from './widgets/AddAudience/AddAudience';
import FilesListingModal from './widgets/FilesListingModal';
import { toast } from 'react-toastify';
import Sidebar from './widgets/Sidebar';
import Contacts from './widgets/Contacts';
import PresetsModal from './widgets/PresetsModal';
import BottomBar from './widgets/BottomBar';

//styles
import styles from './styles.module.scss';

//apis
import { useLazyDeletePersonaQuery } from 'src/redux/queries/Personas';
import { useLazyDeleteAudienceQuery } from 'src/redux/queries/Audience';
import { useLazyGetPresetsQuery } from 'src/redux/queries/Presets';

interface IFields {
    label: string;
    placeholder: string;
    value: string | { label: string; value: string };
    error?: string;
    id: string;
    ref?: (el: HTMLInputElement | null) => HTMLInputElement | null;
    tooltip?: string;
    inputType?: string;
    selectOptions?: { label: string; value: string }[];
    placement?: MenuPlacement;
    isMulti?: boolean;
    customCss?: React.CSSProperties;
}

const ContentForm = ({
    data,
    updateData = () => {},
    errors = {},
    handleError = () => {},
    personas = [],
    audiences = [],
    handleSubmit = () => {},
    onAddPersonaSuccess = () => {},
    onAddAudienceSuccess = () => {}
}: IContentForm) => {
    const loc = useLocation();

    const dispatch = useDispatch();

    const [deletePersona] = useLazyDeletePersonaQuery();
    const [deleteAudience] = useLazyDeleteAudienceQuery();
    const [getPresets] = useLazyGetPresetsQuery();

    const [isPersonaModalOpen, setIsPersonaModalOpen] = useState(false);
    const [isAudienceModalOpen, setIsAudienceModalOpen] = useState(false);
    const [personaData, setPersonaData] = useState({});
    const [audienceData, setAudienceData] = useState({});
    const [showFileListing, setShowFileListing] = useState(false);
    const [showSavePreset, setShowSavePreset] = useState(false);
    const [presets, setPresets] = useState<[]>([]);

    const isBdrCadence = true;

    const errorRefs = useRef<{ [key: string]: HTMLInputElement | HTMLSpanElement | null }>({});

    useEffect(() => {
        updateData({ ...data, contentType: ['advanced-bdrCadence'] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc]);

    useEffect(() => {
        document.title = 'Campaign Like a Pro - Create Campaign';
    }, []);

    useEffect(() => {
        fetchAllPresets();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAllPresets = async () => {
        await getPresets()
            .unwrap()
            .then((data) => {
                console.log('data >>>> ', data);
                setPresets(data.data.presets);
            });
    };

    const handleFileUpload = async (files: [], index: number) => {
        sessionStorage.removeItem('summary');
        analytics.sendClickEvent(
            'file_upload_click',
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.FILE_UPLOAD
        );
        if (files) {
            const file = data.file;
            file[index].files = files;
            updateData({ ...data, file });
            handleError({ ...errors, file: '' });
        }
    };

    const handelTextInput = (value: string, index: number) => {
        sessionStorage.removeItem('summary');
        const file = data.file;
        file[index].value = value;
        updateData({
            ...data,
            file
        });
        handleError({ ...errors, file: '' });
    };

    const handleUrlInput = (urls = [], index: number) => {
        sessionStorage.removeItem('summary');
        const file = data.file;
        file[index].value = urls;
        updateData({ ...data, file });
        handleError({ ...errors, file: '' });
    };

    const onSubmit = () => {
        const errors = validateData(data);

        if (errors && Object.values(errors).length) {
            Object.keys(errors).map((field) => errorRefs?.current[field]?.focus());
        }

        handleSubmit();
    };

    const inputOptions = (types = [] as IContentFormData['file']) => {
        return types?.map((type, key) => {
            switch (type?.type) {
                case CONTENT_FILE_TYPES.TEXT.value:
                    return (
                        <CustomTextArea
                            value={type.value as string}
                            onChange={(e: string) => handelTextInput(e, key)}
                            key={key}
                        />
                    );
                case CONTENT_FILE_TYPES.DOCX.value:
                    return (
                        <FileUpload
                            handleUpload={(files = []) => handleFileUpload(files, key)}
                            files={type.files}
                            type={type.type}
                            multiple
                            ctaTitle={STRINGS.SELECT_DOCX}
                            ctaSelectedTitle={STRINGS.SELECTED_DOCX}
                            key={key}
                        />
                    );

                case CONTENT_FILE_TYPES.URL.value:
                    return (
                        <CustomInputSeparator
                            values={[...type.value]}
                            onChange={(urls = []) => handleUrlInput(urls, key)}
                            key={key}
                        />
                    );
                default:
                    return (
                        <div key={key} className={cx('flex items-center')}>
                            <CustomButton
                                title="Select existing file(s)"
                                onClick={handleShowFileListing}
                                className={cx('h-10 w-44', 'mr-4')}
                            />
                            <FileUpload
                                handleUpload={(files = []) => handleFileUpload(files, key)}
                                files={type.files}
                                multiple
                                ctaTitle={STRINGS.SELECT_PDF}
                                ctaSelectedTitle={STRINGS.SELECTED_PDF}
                                key={key}
                            />
                        </div>
                    );
            }
        });
    };

    const handleShowFileListing = () => {
        setShowFileListing(true);
    };

    const handleCloseFileListing = () => {
        setShowFileListing(false);
    };

    const handleFileSelect = (files: { name: string; url: string }[]) => {
        if (files.length) {
            const file = [...data.file];
            const index = data.file?.findIndex((file) => file.type === 'pdf');
            file[index].files = files;
            updateData({ ...data, file });
            handleError({ ...errors, file: '' });
        }
    };

    const setContentInputType = (type: string) => {
        let typeObject = { type, files: [], value: '' };
        const file = data.file;
        const pos = file.findIndex((f) => f.type === type);
        if (pos !== -1) {
            file.splice(pos, 1);
        } else {
            file.push(typeObject);
        }

        sessionStorage.removeItem('summary');

        updateData({ ...data, file: [...file] });
        handleError({ ...errors, file: '' });
        analytics.sendClickEvent(
            `source_asset_${type}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_SOURCE_TYPE
        );
    };

    const PERSONAS = useMemo(() => {
        if (personas?.length) return [...personas];
        return [...PERSONA];
    }, [personas]);

    const handleSelectPersona = (persona: IPersona) => {
        updateData({
            ...data,
            persona: persona,
            toneRegister: persona.tone
        });
        handleError({ ...errors, persona: '' });
    };

    const handleSelectAudience = (audience: IAudience) => {
        let selectedAudience = [...data.audience];
        let pos = selectedAudience.findIndex((audi) => audi.value === audience.value);

        if (pos !== -1) {
            selectedAudience.splice(pos, 1);
        } else {
            selectedAudience.push(audience);
        }
        updateData({
            ...data,
            audience: selectedAudience
        });
        handleError({ ...errors, audience: '' });
        analytics.sendClickEvent(
            `audience_type_${audience?.value}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_AUDIENCE_TYPE
        );
    };

    const handleToneChange = (tone: { value: any }) => {
        updateData({ ...data, toneRegister: tone.value });
        handleError({ ...errors, toneRegister: '' });
        analytics.sendClickEvent(
            `tone_type_${tone.value}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_TONE_TYPE
        );
    };

    const handleFieldInputChange = (e: { target: { id: string; value: string } }) => {
        const { id, value } = e?.target;
        updateData({ ...data, [id]: value });
        handleError({ ...errors, [id]: '' });
    };

    const handleOrgUpdate = (e: { target: { value: { label: string; value: string } } }) => {
        const { label, value } = e?.target?.value;
        updateData({ ...data, orgCode: value, orgName: label });
    };

    const renderAddPersona = () => {
        const handleAddPersonaSuccess = (persona: IPersona, isEditing: boolean) => {
            if (isEditing) {
                let pos = personas.findIndex((per) => per._id === persona._id);
                if (pos !== -1) personas.splice(pos, 1, persona);
                onAddPersonaSuccess([...personas] || []);
            } else {
                onAddPersonaSuccess([...personas, persona] || []);
            }
            handleSelectPersona(persona);
        };

        return (
            <AddPersona
                isOpen={isPersonaModalOpen}
                onClose={setIsPersonaModalOpen}
                onSuccess={handleAddPersonaSuccess}
                data={personaData}
                setData={setPersonaData}
            />
        );
    };

    const renderAddAudience = () => {
        const handleAddAudienceSuccess = (audience: IAudience, isEditing: boolean) => {
            if (isEditing) {
                let pos = audiences.findIndex((audi) => audi._id === audience.id);
                if (pos !== -1) audiences.splice(pos, 1, audience);
                onAddAudienceSuccess([...audiences] || []);
            } else {
                onAddAudienceSuccess([...audiences, audience] || []);
            }
            handleSelectAudience(audience);
        };

        return (
            <AddAudience
                isOpen={isAudienceModalOpen}
                onClose={setIsAudienceModalOpen}
                onSuccess={handleAddAudienceSuccess}
                data={audienceData}
                setData={setAudienceData}
            />
        );
    };

    const handleAddPerson = (persona?: IPersona) => {
        if (persona) {
            setPersonaData(persona);
        }
        setIsPersonaModalOpen(true);
    };

    const handleAddAudience = (audience: IAudience) => {
        if (audience) {
            setAudienceData(audience);
        }
        setIsAudienceModalOpen(true);
    };

    const handleDeletePersona = async (id: string) => {
        try {
            dispatch(startLoading());
            let res = await deletePersona(id).unwrap();
            let pos = personas.findIndex((per) => per._id === id);
            if (pos !== -1) personas.splice(pos, 1);
            onAddPersonaSuccess([...personas] || []);
            toast.success(res?.message);
        } catch (error: any) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleDeleteAudience = async (id: string) => {
        try {
            dispatch(startLoading());
            let res = await deleteAudience(id).unwrap();
            let pos = audiences.findIndex((audi) => audi._id === id);
            if (pos !== -1) audiences.splice(pos, 1);
            onAddAudienceSuccess([...audiences] || []);
            toast.success(res?.message);
        } catch (error: any) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const renderMain = () => {
        const className = cx(styles.mainField, '');
        const renderFields = () => {
            const Fields: IFields[] = [
                {
                    label: STRINGS.CAMPAIGN_NAME,
                    placeholder: '',
                    value: data.title,
                    error: errors?.title,
                    id: 'title',
                    ref: (el: HTMLInputElement | null) => (errorRefs.current['title'] = el)
                },
                {
                    label: STRINGS.BDR_TYPE,
                    placeholder: '',
                    value: data.subType,
                    error: errors?.subType,
                    id: 'subType',
                    tooltip: '',
                    inputType: 'select',
                    selectOptions: BDR_CADENCE_OPTIONS,
                    placement: 'bottom',
                    isMulti: false,
                    ref: (el: HTMLInputElement | null) => (errorRefs.current['subType'] = el)
                },
                {
                    label: STRINGS.INSTRUCTIONS,
                    placeholder: '',
                    value: data.instructions || '',
                    id: 'instructions',
                    tooltip: 'Add instructions for this campaign',
                    inputType: 'textarea',
                    customCss: { flex: 1 }
                }
            ];

            return Fields.map((field, key) => {
                return (
                    <CustomInput
                        key={key}
                        id={field.id}
                        label={field.label}
                        inputClass={styles.mainInput}
                        value={field.value}
                        onChange={handleFieldInputChange}
                        error={field.error}
                        inputType={field.inputType}
                        className={className}
                        selectOptions={field.selectOptions}
                        placement={field.placement}
                        tooltipText={field.tooltip}
                        isMulti={field.isMulti}
                        labelClass={styles.label}
                        inputProps={{ ref: field.ref }}
                        customCss={field.customCss}
                    />
                );
            });
        };

        return (
            <div className={styles.main}>
                <div className={cx('flex flex-wrap w-100 justify-between border-b-2 pb-2')}>
                    {renderFields()}
                </div>

                <div className={styles.doc}>
                    <span
                        tabIndex={0}
                        ref={(el) => (errorRefs.current['file'] = el)}
                        className={styles.configureTitleError}
                    >
                        {errors?.file}
                    </span>
                    {inputOptions(data.file)}
                </div>

                {/* {PERSONAS?.length >= 1 && (
                    <RadioBlock
                        heading={STRINGS.WRITER_PERSONA}
                        headingTooltip={STRINGS.PERSONA_TOOLTIP}
                        data={PERSONAS}
                        error={errors?.persona || errors?.toneOutlook}
                        onSelect={handleSelectPersona}
                        value={data.persona?.name}
                        allowAdd
                        handleAdd={handleAddPerson}
                        handleDelete={handleDeletePersona}
                        deleteTitle={STRINGS.DELETE_PERSONA}
                        deleteDesc={STRINGS.DELETE_PERSONA_DESC}
                    />
                )} */}

                <CheckBlock
                    heading={STRINGS.AUDIENCE_TYPE}
                    data={audiences.filter((audi) => audi.type !== 'personalized')}
                    error={errors?.audience}
                    onSelect={handleSelectAudience}
                    selectedValues={data.audience}
                    allowAdd
                    handleAdd={handleAddAudience}
                    handleDelete={handleDeleteAudience}
                    deleteTitle={STRINGS.DELETE_AUDIENCE}
                    deleteDesc={STRINGS.DELETE_AUDIENCE_DESC}
                    errorRef={(el: HTMLInputElement | null) => (errorRefs.current['audience'] = el)}
                />

                <Contacts
                    handleSelectContacts={handleSelectContacts}
                    groupBy={data.groupBy}
                    selectedContacts={data.contacts}
                    error={errors?.contacts}
                />

                <div className={styles.mainFooter}>
                    <CustomButton title={STRINGS.NEXT} onClick={onSubmit} />
                </div>

                <BottomBar data={data} />
            </div>
        );
    };

    const handleSelectContacts = (contacts: IContacts[], groupBy: string) => {
        updateData({
            ...data,
            contacts,
            groupBy
        });
    };

    const handleEmotionalFilterChange = (emotionalFilter: { value: string }) => {
        updateData({ ...data, emotionalFilter: emotionalFilter.value });
    };

    const handleContentFilterChange = (contentFilter: { value: string }) => {
        updateData({ ...data, contentFilter: contentFilter.value });
    };

    const handlePersonaFilterChange = (personaFilter: { value: string }) => {
        updateData({ ...data, personaFilter: personaFilter.value });
    };

    const handleShowPreset = () => {
        setShowSavePreset(true);
    };

    const handleCancel = () => {
        setShowSavePreset(false);
    };

    const handleApplyPreset = (preset: IPreset) => {
        updateData({
            ...data,
            toneRegister: preset.tone,
            emotionalFilter: preset.emotionalFilter,
            contentFilter: preset.contentFilter,
            personaFilter: preset.personaFilter,
            subType: preset.bdrType
        });
    };

    return (
        <div className={styles.container}>
            <Sidebar
                data={data}
                errors={errors}
                presets={presets}
                applyPreset={handleApplyPreset}
                setContentInputType={setContentInputType}
                handleToneChange={handleToneChange}
                handleOrgUpdate={handleOrgUpdate}
                handleEmotionalFilterChange={handleEmotionalFilterChange}
                handleContentFilterChange={handleContentFilterChange}
                handlePersonaFilterChange={handlePersonaFilterChange}
                handeSavePreset={handleShowPreset}
            />
            {renderMain()}
            {isPersonaModalOpen && renderAddPersona()}
            {isAudienceModalOpen && renderAddAudience()}

            {showFileListing && (
                <FilesListingModal
                    orgCode={data.orgCode}
                    closeModal={handleCloseFileListing}
                    handleFileSelect={handleFileSelect}
                    files={data.file?.find((file) => file.type === 'pdf')?.files || []}
                    containerClass={cx(styles.listing)}
                />
            )}

            {showSavePreset && <PresetsModal data={data} handleClose={handleCancel} />}
        </div>
    );
};

export default React.memo(ContentForm);
