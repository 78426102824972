import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
};

let token = '';

export const fbApi = createApi({
    reducerPath: 'fbApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/fb',
        prepareHeaders: (headers, { getState }) => {
            token = getState().user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        initFb: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/`
                };
            }
        }),
        oauthFb: builder.query({
            query: ({ code }) => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/oauth-callback`,
                    params: { code }
                };
            }
        }),
        fbUserInfo: builder.query({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/info`
                };
            }
        }),
        fbLogout: builder.mutation({
            query: () => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/logout`
                };
            }
        }),
        shareFb: builder.mutation({
            query: (payload) => {
                return {
                    method: 'Post',
                    headers: { ...headers },
                    url: `/share`,
                    body: payload
                };
            }
        })
    })
});

export const {
    useLazyInitFbQuery,
    useLazyOauthFbQuery,
    useLazyFbUserInfoQuery,
    useFbLogoutMutation,
    useShareFbMutation
} = fbApi;
