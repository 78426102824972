//libs
import React, { useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';

//styles
import S from './ForgotPassword.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';
import { ROUTES } from 'src/shared/constants';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';

//api
import { useForgotPasswordMutation } from 'src/redux/queries/User';

//helpers
import { startLoading, stopLoading } from 'src/redux/reducers';

//hooks
import { useDispatch } from 'react-redux';
import useCustomNavigation from 'src/hooks/useCustomNavigations';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const handleNavigation = useCustomNavigation();

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const [sendForgotPassword] = useForgotPasswordMutation();

    const handleChange = (e: { target: { value: any } }) => {
        setError('');
        setEmail(e.target.value?.trim());
    };

    const handleSubmit = async (
        e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>
    ) => {
        e.preventDefault();
        let error = validate(email);
        setError(error);
        if (error) return;

        try {
            dispatch(startLoading());
            await sendForgotPassword({ email })
                .unwrap()
                .then((res) => {
                    if (res?.statusCode === 2) {
                        toast.error(res?.message, { toastId: '1' });
                    } else {
                        toast.success(res?.message, { toastId: '2' });
                        handleNavigation(ROUTES.RESET_PASSWORD);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.data?.message, { toastId: '0' });
                });
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <section className={S.section}>
            <div
                className={cx(
                    'flex items-center justify-between',
                    'gap-8 m-auto p-8',
                    'bg-white',
                    'rounded shadow'
                )}
            >
                <div className={cx('flex')}>
                    <h3 className={'text-3xl'}>{STRINGS.FORGOT_PASSWORD}</h3>
                </div>
                <div className={'flex flex-col ml-2'}>
                    <h1 className={cx('my-2', 'text-xl')}>{STRINGS.FORGOT_PASSWORD_MESSAGE}</h1>
                    <form onSubmit={handleSubmit}>
                        <CustomInput
                            label="Email"
                            id={'email'}
                            error={error}
                            onChange={handleChange}
                            value={email}
                        />
                        <div className="flex justify-center mt-4">
                            <CustomButton
                                title={STRINGS.SEND}
                                className={S.containerRightButton}
                                onClick={handleSubmit}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default React.memo(ForgotPassword);

const validate = (email: string) => {
    let error = '';
    if (!email) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        error = 'Invalid email';
    }
    return error;
};
