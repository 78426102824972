import React from 'react';

//styles
import styles from './styles.module.scss';

//hooks
import useClickOutside from 'src/hooks/useClickOutside';

//constants
import { STRINGS } from 'src/shared/strings';

const DeleteModal = ({ handleDelete, setIsOpen, title = '', desc = '', yesTitle = '' }) => {
    const handleClickOutside = () => {};

    const ModalRef = useClickOutside(handleClickOutside);

    return (
        <div className={styles.overlay}>
            <div className={styles.delete} ref={ModalRef}>
                <div className={styles.deleteTitle}>{title} </div>
                <div className={styles.deleteSubtitle}>{desc}</div>
                <div className={styles.deleteCtas}>
                    <div className={styles.deleteCtasBtn} onClick={() => setIsOpen(false)}>
                        {STRINGS.CANCEL}
                    </div>
                    <div className={styles.deleteCtasBtn} onClick={handleDelete}>
                        {yesTitle || STRINGS.DELETE}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(DeleteModal);
