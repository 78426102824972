import React from 'react';
import cx from 'classnames';

// constants
import { ICON_ASCENDING, ICON_DESCENDING } from 'src/shared/constants/icons';

interface IList {
    id: string;
    label: string;
    enableSort?: boolean;
    medium?: boolean;
}

interface IProps {
    list: IList[];
    className: string;
    handleOrderChange: (data: { order: number; sortBy: string }) => void;
}

const Headings = ({ list, className, handleOrderChange }: IProps) => {
    const handleAscending = (e: any) => {
        handleOrderChange({
            sortBy: e.target.id,
            order: 1
        });
    };

    const handleDescending = (e: any) => {
        handleOrderChange({
            sortBy: e.target.id,
            order: -1
        });
    };

    return (
        <>
            {list.map(({ id, label, enableSort, medium }) => {
                return (
                    <div
                        key={id}
                        className={cx(
                            medium && '[&&]:w-[132px] [&&]:min-w-[132px]',
                            className,
                            'flex items-center'
                        )}
                    >
                        {!!enableSort && (
                            <span
                                id={id}
                                className={cx(
                                    ICON_ASCENDING,
                                    'text-xs text-slate-900',
                                    'mr-1',
                                    'cursor-pointer'
                                )}
                                onClick={handleAscending}
                            />
                        )}
                        <span>{label}</span>
                        {!!enableSort && (
                            <span
                                id={id}
                                className={cx(
                                    ICON_DESCENDING,
                                    'text-xs text-slate-900',
                                    'ml-1',
                                    'cursor-pointer'
                                )}
                                onClick={handleDescending}
                            />
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default React.memo(Headings);
