import type { IContacts } from 'src/defs';

export const removeContactFields = (contact: IContacts, groupBy: string): IContacts => {
    const {
        lastName,
        firstName,
        email,
        categories,
        orgId,
        userId,
        userEmail,
        createdAt,
        updatedAt,
        personInsight,
        personalMotivators,
        __v,
        ...rest
    } = contact;

    if (groupBy.toLowerCase() === 'industry') {
        delete rest.companyName;
        delete rest.companyAddress;
        delete rest.websiteURL;
        delete rest.linkedin_bio;
        delete rest.enrichedData;
        delete rest.jobTitle;
        delete rest.jobResponsibilities;
        delete rest.businessOutcomes;
        delete rest.commonObjection;
        delete rest.frustrationsAndChallenges;
    }

    return rest as IContacts;
};

export function maskEmail(email: string) {
    if (!email) return '';
    const [username, domain] = email.split('@');
    const maskedUsername = username.slice(0, 2) + '*'.repeat(username.length - 2);
    return `${maskedUsername}@${domain}`;
}
