//lib
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//defs
import type { ISidebarChips } from 'src/defs';
import type { RootState } from 'src/redux/store';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//constants
import { ROUTES } from 'src/shared/constants';
import { STRINGS } from 'src/shared/strings';
import {
    ICON_CASE_STUDY,
    ICON_CONSTRUCTION,
    ICON_ORGANIZATION,
    ICON_USERS
} from 'src/shared/constants/icons';

//styles
import styles from './styles.module.scss';

//assets
import DEFAULT_USER from 'src/assets/icons/user.svg';
import Icomoon from 'src/assets/Icomoon';

interface IProps {
    setShowAddCampaignModal: (value: boolean) => void;
}

const chipContainer = cx('border-b-2 mb-4 relative', styles.chip);
const chipCta = cx(
    'flex items-center',
    'py-2 px-2',
    'text-base font-medium',
    'cursor-pointer rounded-lg',
    styles.chipCta
);
const chipIcon = cx(styles.chipIcon, 'text-[24px]');

const AppSidebar = ({ setShowAddCampaignModal }: IProps) => {
    const userDetails = useSelector((state: RootState) => state.user.userDetails);
    const config = useSelector((state: RootState) => state.config);

    const handleNavigation = useCustomNavigation();
    const loc = useLocation();

    const [fullSidebar, setFullSidebar] = useState(true);

    useEffect(() => {
        if (loc?.pathname !== ROUTES.HOME) {
            setFullSidebar(false);
        } else {
            setFullSidebar(true);
        }
    }, [loc]);

    const gotToProfile = () => {
        handleNavigation(ROUTES.PROFILE);
    };

    const gotToHome = () => {
        handleNavigation(ROUTES.HOME);
    };

    const goToOrg = () => {
        handleNavigation(ROUTES.ORG);
    };

    const goToContacts = () => {
        handleNavigation(ROUTES.CONTACT_DETAILS);
    };

    const goToCaseStudy = () => {
        handleNavigation(ROUTES.CASE_STUDIES);
    };

    const AppLogo = () => {
        return (
            <h1
                onClick={gotToHome}
                className={cx(styles.containerTitle, {
                    [styles.containerTitleSmall]: !fullSidebar
                })}
                aria-label={STRINGS.APP_NAME}
            >
                {fullSidebar ? (
                    STRINGS.APP_NAME
                ) : (
                    <Icomoon className={styles.containerTitleIcon} icon={'home'} />
                )}
            </h1>
        );
    };

    const UserChip = () => {
        return (
            <div
                className={cx(styles.user, { [styles.userSmall]: !fullSidebar })}
                onClick={gotToProfile}
            >
                <div className={cx(styles.userImage, { [styles.userSmallImage]: !fullSidebar })}>
                    <img
                        className={styles.userImagePic}
                        src={userDetails?.picture || DEFAULT_USER}
                        alt=""
                    />
                </div>
                {fullSidebar && (
                    <div className={styles.userDetail}>
                        <div className={styles.userDetailName}>
                            {userDetails?.name?.split(' ')[0]}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    // const Campaigns = () => {
    //     if (!fullSidebar) return null;
    //     return (
    //         <div className={styles.campaign}>
    //             <div className={styles.title}>{STRINGS.CAMPAIGNS}</div>
    //             <ul className={styles.campaignList}>
    //                 <li className={styles.campaignListItem}>Campaign 1</li>
    //                 <li className={styles.campaignListItem}>Campaign 2</li>
    //             </ul>
    //         </div>
    //     );
    // };

    const AddCampaign = () => {
        return (
            <div
                className={cx(styles.add, { [styles.addSmall]: !fullSidebar })}
                onClick={() => setShowAddCampaignModal(true)}
            >
                <Icomoon className={styles.addIcon} icon={'add'} />
            </div>
        );
    };

    const Chips = ({ icon, path, title, handleClick, disabled = false }: ISidebarChips) => {
        return (
            <div className={chipContainer}>
                <div
                    className={cx(
                        chipCta,
                        {
                            [styles.chipActive]: loc.pathname?.includes(path)
                        },
                        disabled && 'pointer-events-none text-gray-400'
                    )}
                    onClick={handleClick}
                >
                    <div className={cx(chipIcon, icon)} />
                    {fullSidebar && title}
                </div>
                {disabled && (
                    <div
                        className={cx(
                            'absolute top-0 right-[-4px]',
                            '[&&]:text-lg [&&]:font-medium text-green-600',
                            ICON_CONSTRUCTION
                        )}
                    />
                )}
            </div>
        );
    };

    return (
        <div className={cx(styles.container, { [styles.containerSmall]: !fullSidebar })}>
            <div className="w-full">
                <AppLogo />
                <AddCampaign />
                {/* <Campaigns /> */}
            </div>

            <div className="w-full">
                {/* <Chips
                    icon={ICON_CASE_STUDY}
                    path={ROUTES.CASE_STUDIES}
                    title={STRINGS.CASE_STUDIES}
                    handleClick={goToCaseStudy}
                /> */}
                <Chips
                    icon={ICON_USERS}
                    path={ROUTES.CONTACT_DETAILS}
                    title={STRINGS.CONTACTS}
                    handleClick={goToContacts}
                    disabled={!config.enableContacts}
                />
                <Chips
                    icon={ICON_ORGANIZATION}
                    path={ROUTES.ORG}
                    title={STRINGS.ORG}
                    handleClick={goToOrg}
                />
                <UserChip />
            </div>
        </div>
    );
};

export default React.memo(AppSidebar);
