//libs
import React, { useState } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//widgets
import Tooltip from 'src/components/Tooltip';
import { RadioButton } from 'src/widgets';
import { ICON_TRIANGLE_DOWN, ICON_TRIANGLE_RIGHT } from 'src/shared/constants/icons';

interface IProps {
    heading: string;
    data: { label: string; value: string; icon?: string }[];
    onSelect: (item: { label: string; value: string }) => void;
    value: string;
    error?: string;
    headingTooltip?: string;
}

const IconCss = cx('mr-1', 'w-[16px] h-[16px]', 'text-[16px]', 'text-slate-500');

const RadioTitle = ({
    heading = '',
    headingTooltip = '',
    data = [],
    error = '',
    onSelect,
    value = ''
}: IProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (item: { label: string; value: string }) => {
        onSelect(item);
    };

    const radioClass = (item: { label: string; value: string }) =>
        cx(styles.containerContentRadio, {
            [styles.containerContentRadioChecked]: value === item.value
        });

    return (
        <div className={styles.container}>
            <div className={styles.containerTitle} onClick={toggleDropdown}>
                <div className={cx('flex items-center')}>
                    <span
                        className={cx(
                            'mr-2',
                            'text-slate-500 text-lg',
                            isOpen ? ICON_TRIANGLE_DOWN : ICON_TRIANGLE_RIGHT
                        )}
                    />
                    <Tooltip
                        className={styles.containerTitleText}
                        title={heading}
                        tooltipText={headingTooltip}
                    />
                </div>
                <span className={styles.containerTitleError}>{error}</span>
            </div>

            {isOpen && (
                <div className={styles.dropdownContent}>
                    {data.map((item, key) => (
                        <RadioButton
                            className={radioClass(item)}
                            inputClass={styles.containerContentRadioCta}
                            key={key}
                            data={item}
                            id={`${heading}_${key}`}
                            onChange={() => handleSelect(item)}
                            checked={value === item.value}
                            iconStart={item.icon ? cx(IconCss, item.icon) : ''}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default React.memo(RadioTitle);
