import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface IErrorPayload {
    error: string;
    api: string;
}

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

let token: string = '';

export const logsApi = createApi({
    reducerPath: 'logsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/logs',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            // Specify RootState to ensure proper typing
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        errorLogs: builder.mutation<void, IErrorPayload>({
            query: (payload: IErrorPayload) => {
                return {
                    method: 'POST',
                    headers: { ...headers },
                    url: `/error`,
                    body: payload
                };
            }
        })
    })
});

// Exporting hooks generated by the API
export const { useErrorLogsMutation } = logsApi;
