import React from 'react';

//styles
import styles from './Sidebar.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';
import { CONTENT_FILE_TYPES, PERSONA_TONE_OPTIONS } from 'src/shared/constants';

//widgets
import { RadioTitle } from 'src/views/Private/Campaign/widgets/ContentForm/widgets/RadioTitle';
import { CheckTitle } from 'src/views/Private/Campaign/widgets/ContentForm/widgets/CheckTitle';
import { CustomInput } from 'src/widgets';

//helpers
import { useSelector } from 'react-redux';

interface IProps {
    data: {
        file: any;
        toneRegister: string;
        mType?: string;
        orgCode?: string;
        orgName?: string;
    };
    errors: { campaignOrg?: string };
    setContentInputType: (type: string) => void;
    handleToneChange: (tone: { value: string }) => void;
    handleOrgUpdate: (e: any) => void;
}

const Sidebar = ({
    data,
    errors,
    setContentInputType,
    handleToneChange,
    handleOrgUpdate
}: IProps) => {
    const userOrgs = useSelector((state: any) => state.user.userDetails?.orgs) || [];

    return (
        <div className={styles.sidebar}>
            <CheckTitle
                heading={STRINGS.IMPORT_CONTENT}
                headingTooltip={STRINGS.IMPORT_CONTENT_TOOLTIP}
                data={Object.values(CONTENT_FILE_TYPES)}
                checked={data.file}
                onSelect={setContentInputType}
            />

            <RadioTitle
                heading={STRINGS.TONE_REGISTER_TYPE}
                headingTooltip={STRINGS.TONE_REGISTER_TYPE_TOOLTIP}
                // error={errors?.toneRegister}
                data={PERSONA_TONE_OPTIONS}
                value={data.toneRegister}
                onSelect={handleToneChange}
            />

            <CustomInput
                id={'campaignOrg'}
                label={STRINGS.ORG}
                inputClass={styles.mainInput}
                value={{ label: data.orgName || '', value: data.orgCode || '' }}
                onChange={handleOrgUpdate}
                error={errors.campaignOrg}
                inputType={'select'}
                labelClass={styles.label}
                selectOptions={[
                    ...userOrgs.map((org: { name: string; code: string }) => ({
                        label: org.name,
                        value: org.code
                    }))
                ]}
                placement={'bottom'}
                // tooltipText={field.tooltip}
                isMulti={false}
            />
        </div>
    );
};

export default React.memo(Sidebar);
