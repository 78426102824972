export const STRINGS = {
    APP_NAME: 'Campaign Like a Pro',
    BACK: 'Back',
    NEXT: 'Next',
    START_AGAIN: 'Start Again',
    DOWNLOAD_DOC: 'Download as docx',
    CONTENT: 'Content',
    ACTION: 'Action',
    IMPORT_CONTENT: 'Campaign Input Documents',
    IMPORT_CONTENT_TOOLTIP:
        'Select the documents outlining the themes you want to build your campaign for. 3-6 documents are ideal.',
    SELECT_CONTENT_TYPE: 'Output Content Type',
    SELECT_CONTENT_TYPE_TOOLTIP: 'Specify the type of content you want to create.',
    TONE_REGISTER_TYPE: 'Tone',
    TONE_REGISTER_TYPE_TOOLTIP: 'What personality do you want your content to have?',
    TONE_OUTLOOK_TYPE: 'Problem vs Solution',
    TONE_OUTLOOK_TYPE_TOOLTIP:
        'Do you want your content to focus more on the problem or the solution? Neutral is correct for most content.',
    AUDIENCE_TYPE: 'Audience',
    // AUDIENCE_TYPE: 'What level is your audience?',
    AUDIENCE_TYPE_TOOLTIP: 'For most content, the middle choice works fine',
    DOC_TITLE: 'Campaign Like a Pro',
    ACCEPT: 'Accept',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    TEXT: 'Text',
    NO_DATA_FOUND: 'No data found!',
    DATE: 'Date',
    SERIAL: 'Sr',
    USAGE_SUMMARY: 'My CopyKit History',
    PDF: 'PDF',
    URL: 'URL',
    DOCX: 'Docx',
    LOGIN_WITH: 'Login with',
    DONT_HAVE_ACCOUNT: `Don't have an account?`,
    SIGNUP: 'SignUp',
    ALREADY_HAVE_ACCOUNT: `Already have an account?`,
    EMPTY: '--',
    SHOWING: 'Showing',
    RESULTS_PER_PAGE: 'Results per page',
    WRITER_PERSONA: 'Writer Persona',
    PERSONA_TOOLTIP: 'Select the persona you want to write as',
    DOCS: 'Docs',
    MEMBERS: 'Members',
    ROLE: 'Role',
    ORG: 'Organizations',
    URLS_PLACEHOLDER: 'Add Urls separated by (;)',
    SELECT_DOCX: 'Select Docx file(s)',
    SELECTED_DOCX: 'Docx Selected',
    SELECT_PDF: 'Select PDF file(s)',
    SELECTED_PDF: 'PDF Selected',
    ADD_MEMBER: 'Add Member',
    CLAP_WITH_AI: 'Clap with AI',
    CAMPAIGNS: 'Campaigns',
    HOME: 'Home',
    CAMPAIGN_NAME: 'Campaign Name',
    DELETE: 'Delete',
    DELETE_CAMPAIGN: 'Delete Campaign',
    CANCEL: 'Cancel',
    DELETE_CAMPAIGN_DESC: 'Are you sure you want to delete this campaign?',
    ADD_ORG: 'Add Organisation details',
    EDIT_ORG: 'Edit Organisation details',
    VIEW_ORG: 'View Organisation details',
    WELCOME: 'Welcome!',
    ADD_ORG_DESC:
        'Please provide information about your organization. This helps us create better campaigns for you!',
    WEB_SITE_URL: 'Website',
    ADDRESS_DESC: 'Where are you located?',
    GEO_DESC: 'Which geographies do you sell to?',
    COMMON_CTAS: 'Common CTAs for your campaign',
    SKIP: 'Skip',
    CAMPAIGN_GOAL: 'Goal of the campaign',
    CAMPAIGN_THEME: 'Campaign Theme',
    CAMPAIGN_DEMOGRAPHY: 'what is your demographic profile',
    CAMPAIGN_RELEVANT_PRODUCTS: 'Which product(s), solution(s), service(s) is the campaign about?',
    SEO_KEYWORDS: 'SEO keywords',
    ADD_CUSTOM_PERSONA: 'Add Custom Persona',
    EDIT_CUSTOM_PERSONA: 'Edit Custom Persona',
    ADD_CUSTOM_AUDIENCE: 'Add Custom Audience',
    EDIT_CUSTOM_AUDIENCE: 'Edit Custom Audience',
    DELETE_PERSONA: 'Delete Persona?',
    DELETE_PERSONA_DESC: 'Are you sure you want to delete this persona?',
    DELETE_AUDIENCE: 'Delete Audience?',
    DELETE_AUDIENCE_DESC: 'Are you sure you want to delete this audience?',
    BDR_TYPE: 'BDR Type',
    ORG_CONTENT: 'Org Content',
    CREATE_AUDIENCE_PERSONA: '1. Create Audience Persona Card',
    CLONE_AUDIENCE_PERSONA: '1. Clone Audience Persona Card',
    INSTRUCTIONS: 'Instructions',
    INSTRUCT: 'Instruct',
    OPTIONAL: 'Optional',
    FORGOT_PASSWORD: 'Forgot Password',
    FORGOT_PASSWORD_MESSAGE: 'Enter the email address associated with your account.',
    RESET_PASSWORD: 'Reset Password',
    SEND: 'Send',
    MIN_PASSWORD: 'password should be of more than 12 characters',
    EMAIL_SENT: 'Check your registered email for Authentication Code.',
    CONTACTS: 'Contacts',
    CASE_STUDIES: 'Case Studies'
};

export const LABELS = {
    ACCEPT: 'Accept',
    REGENERATE: 'Regenerate',
    UPLOADED: 'Selected',
    UPLOAD_FILE: 'Select file(s)',
    NO_FILE_CHOOSEN: 'No file chosen',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    GOOGLE: 'Google',
    NAME: 'Name',
    CONFIRM_PASSWORD: 'Confirm Password',
    ADD: 'Add',
    SUBMIT: 'Submit',
    ORG_NAME: 'Organization Name*',
    INDUSTRY: 'Industry*',
    AUDIENCE_SEGMENTS: 'Audience Segment',
    COMPANY: 'Company Mission*',
    TARGET_AUDIENCES: 'Target Audience(s)',
    SUCCESSFUL_CAMPAIGN_URLS: 'Great Campaign Assets',
    UNDER_PERFORMING_URLS: 'Underperforming campaign URLs or documents',
    UPDATE: 'Update',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    CLONE: 'Clone',
    REGENERATE_WITH_INSTRUCTIONS: 'Regenerate with Instructions',
    MERGE: 'Merge',
    CLEAR: 'Clear',
    CODE: 'code',
    CODE_LABEL: 'Authentication Code',
    NEW_PASSWORD: 'newPassword',
    NEW_PASSWORD_LABEL: 'New Password',
    CONFIRM_PASSWORD_ID: 'confirmPassword',
    CONFIRM_PASSWORD_LABEL: 'Confirm Password'
};

export const ERRORS = {
    UPLOAD_FILE: 'Please select file(s)',
    EMPTY_CONTENT_TYPE: 'Please select at least one content type',
    REQUIRED: 'required',
    MAX_TEXT_LIMIIT_REACHED: 'Max 800 words allowed as text input',
    EMPTY_CONTENT_INPUT: 'Please add text in the text box or select another content type',
    EMPTY_URL_INPUT: 'Please add URL(s) or select another content type',
    MAX_WORD_ID: 'maxWordsWarning',
    EMPTY_INPUT: 'Please add text in the text box',
    CAMPAIGN_NOT_FOUND: 'Campagin Not found',
    PASSWORD:
        'should have one uppercase letter, one smallcase letter, one digit, and one special character'
};

export const PERSONA = {
    NAME: 'Name',
    TITLE: 'Title',
    TONE: 'Tone',
    STYLE: 'Writing Style',
    PERSONALITY: 'Personality',
    BEHAVIOUR: 'Writing Behaviour',
    DESCRIPTION: 'Persona Description'
};

export const AUDIENCE = {
    TITLE: 'Title',
    COMPANY_PROFILE: 'Company Name',
    LINKEDIN: 'Linkedin Profile URL',
    KEY_PERFORMANCE_INDICATORS: 'Key Performance Indicators (KPIs)',
    INDUSTRY: 'Industry',
    SEGMENT: 'Segment'
};
