import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//defs
import type { IAudience } from 'src/defs';

interface IAddPreset {
    orgId: string;
    presetName: string;
    presetDesc: string;
    tone: string;
    emotionalFilter: string;
    contentFilter: string;
    personaFilter: string;
    bdrType: { label: string; value: string };
    // audience: IAudience[];
}

interface ISuccess {
    message: string;
    statusCode: string;
    data?: any;
}

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

let token: string = '';

export const presetsApi = createApi({
    reducerPath: 'presetsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/presets',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            // Specify RootState to ensure proper typing
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        addPreset: builder.mutation<ISuccess, IAddPreset>({
            query: (payload) => {
                return {
                    method: 'POST',
                    headers: { ...headers },
                    url: `/`,
                    body: payload
                };
            }
        }),
        getPresets: builder.query<ISuccess, void>({
            query: () => {
                return {
                    method: 'GET',
                    headers: { ...headers },
                    url: `/`
                };
            }
        })
    })
});

// Exporting hooks generated by the API
export const { useAddPresetMutation, useLazyGetPresetsQuery } = presetsApi;
