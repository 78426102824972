import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

// defs
import type { IContacts } from 'src/defs';

// styles
import styles from '../../ContactTable.module.scss';

// widget
import Heading from '../Heading';
import DeleteModal from 'src/components/DeleteModal';
import { toast } from 'react-toastify';

// helpers
import { maskEmail, removeContactFields } from '../../helpers';

// constants
import { CONTACT_HEADINGS, CONTACT_KEYS, SINGLE_LINE_KEYS } from '../../constants';
import { ICON_CANCEL, ICON_PENCIL, ICON_SAVE } from 'src/shared/constants/icons';
import { CustomInput } from 'src/widgets';

interface IProps {
    list: { [key: string]: IContacts[] };
    groupBy: string;
    className?: string;
    handleGroupSelect: (contact: IContacts[], groupBy: string) => void;
    handleOrderChange: (data: { order: number; sortBy: string }) => void;
    selectedGroup: IContacts[];
    handleUpdateContact: (contacts: { [key: string]: string }) => Promise<void>;
}

const tableDataRowStyles = cx(
    'my-5 mx-4 text-justify w-[240px]',
    'cursor-pointer',
    styles.data,
    styles.colData
);

const rowStyle = cx('flex flex-1');

const nameStyles = cx('[&&]:w-[132px] [&&]:min-w-[132px]');

const tableDataHeadingStyle = cx(
    'bg-white',
    'flex items-center',
    'py-2 mx-4 text-justify w-[240px]',
    styles.data
);

const GroupedTableData = ({
    list,
    groupBy,
    selectedGroup,
    handleGroupSelect,
    handleOrderChange,
    handleUpdateContact
}: IProps) => {
    const [checkedGroups, setCheckedGroups] = useState<IContacts[]>([...selectedGroup]);
    const [isEditing, setIsEditing] = useState<string>('');
    const [contactsData, setContactsData] = useState<
        {
            group: string;
            leads: IContacts[];
        }[]
    >([]);
    const [updatedData, setUpdatedData] = useState<{ [key: string]: string }>({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const tableRowRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    const groupedContacts = Object.entries(list).map(([key, value]) => ({
        group: key,
        leads: value as IContacts[]
    }));

    useEffect(() => {
        setContactsData(() => groupedContacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    useEffect(() => {
        setCheckedGroups([...selectedGroup]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupBy]);

    useEffect(() => {
        handleGroupSelect(checkedGroups, groupBy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedGroups]);

    const hanldeCheckBoxSelect = (value: string) => {
        if (value === 'all') {
            if (checkedGroups.length === contactsData.length) {
                setCheckedGroups([]);
            } else {
                setCheckedGroups(() =>
                    contactsData.map((contact) => removeContactFields(contact.leads[0], groupBy))
                );
            }
        } else {
            let pos = checkedGroups.findIndex((contact) => contact[groupBy] === value);
            if (pos !== -1) {
                setCheckedGroups((prev) => prev.filter((_, index) => index !== pos));
            } else {
                let contact = contactsData.find((gc) => gc.leads[0][groupBy] === value);
                if (contact?.leads?.[0]) {
                    setCheckedGroups([
                        ...checkedGroups,
                        removeContactFields(contact.leads[0], groupBy)
                    ]);
                }
            }
        }
    };

    const handleRowClick = (e: any, key: number) => {
        if (isEditing !== null) return;

        if (e.target?.classList?.contains(styles.colData)) {
            e.target?.classList?.remove(styles.colData);
        } else {
            e.target?.classList?.add(styles.colData);
        }
    };

    const handleEditContact = (groupIdx: number, idx: number, value: string, key: string) => {
        const updatedContact = JSON.parse(JSON.stringify(contactsData));

        updatedContact[groupIdx].leads[idx] = {
            ...contactsData[groupIdx].leads[idx],
            [key]: value
        };
        setContactsData(() => updatedContact);

        setUpdatedData((state) => ({
            id: contactsData[groupIdx].leads[idx]._id,
            ...state,
            [key]: value
        }));
    };

    const handleSave = async (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!Object.keys(updatedData)?.length) {
            toast.warning('Please make some changes to update the contact information.');
            return;
        }

        setShowUpdateModal(true);
    };

    const handleUpdate = async () => {
        setShowUpdateModal(false);
        await handleUpdateContact(updatedData);
        setIsEditing('');
        setUpdatedData({});
    };

    const handleEdit = (e: MouseEvent<HTMLDivElement>, idxKey: string) => {
        e.stopPropagation();

        if (isEditing !== idxKey) {
            setUpdatedData({});
            setContactsData(() => groupedContacts);
            setIsEditing(idxKey);
        }
    };

    const handleCancel = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsEditing('');
        setUpdatedData({});
        setContactsData(() => groupedContacts);
    };

    const renderInput = (contact: IContacts, groupIdx: number, idx: number) => {
        return (
            <div className={cx('flex')}>
                {CONTACT_KEYS.map((key, index) => {
                    const isName = ['firstName', 'lastName', 'kloutScore'].includes(key);
                    const isSingleInput = SINGLE_LINE_KEYS.includes(key);
                    const isEmail = key === 'email';
                    return (
                        <CustomInput
                            key={index}
                            value={
                                (isEmail ? maskEmail(contact[key]) : contact[key] || '') as string
                            }
                            className={cx(
                                tableDataRowStyles,
                                '[&&]:p-0 [&&]:rounded',
                                isName && nameStyles
                            )}
                            onChange={(e: any) =>
                                handleEditContact(groupIdx, idx, e.target.value, key)
                            }
                            inputType={isSingleInput ? '' : 'textarea'}
                        />
                    );
                })}
            </div>
        );
    };

    const renderText = (contact: IContacts) => {
        return (
            <div className={cx('flex')}>
                {CONTACT_KEYS.map((key, index) => {
                    const isName = ['firstName', 'lastName', 'kloutScore'].includes(key);
                    const isEmail = key === 'email';
                    return (
                        <div
                            key={index}
                            className={cx(
                                tableDataRowStyles,
                                isName && nameStyles,
                                isEmail && 'text-gray-500'
                            )}
                        >
                            {isEmail ? maskEmail(contact[key]) : contact[key]}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={cx(styles.table)}>
            <div className={cx(rowStyle, 'bg-white', 'sticky top-0 z-10', 'inline-flex')}>
                <div className={cx(tableDataRowStyles, styles.wCheck, 'flex items-center')}>
                    <input
                        type="checkbox"
                        value={contactsData.length}
                        checked={selectedGroup.length === contactsData.length}
                        onChange={() => hanldeCheckBoxSelect('all')}
                        className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                    />
                </div>

                <div className={cx(tableDataHeadingStyle, styles.w100)}>SR.No</div>
                <div className={cx(tableDataHeadingStyle, styles.w40)} />

                <Heading
                    list={CONTACT_HEADINGS}
                    className={cx(tableDataHeadingStyle)}
                    handleOrderChange={handleOrderChange}
                />
            </div>

            {contactsData.map((contact, idx) => {
                return (
                    <div key={idx} className={cx('mb-4', 'inline-flex flex-col', 'border-t-2')}>
                        <div className={cx('flex items-center', 'font-semibold', 'my-2')}>
                            <div className={cx(tableDataRowStyles, styles.wCheck, 'h-5')}>
                                <input
                                    type="checkbox"
                                    value={idx}
                                    checked={
                                        !!selectedGroup?.find(
                                            (group: any) =>
                                                group[groupBy] === contact?.leads[0][groupBy]
                                        )
                                    }
                                    onChange={() =>
                                        hanldeCheckBoxSelect(contact.leads[0][groupBy] as string)
                                    }
                                    className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                                />
                            </div>
                            <div>{contact.group}</div>
                        </div>
                        <div className={cx('grid', 'my-2')}>
                            {contact.leads.map((lead, key) => {
                                const bg = key % 2 === 0 ? 'bg-slate-200' : 'bg-slate-300';

                                return (
                                    <div
                                        key={key}
                                        className={cx(rowStyle, bg)}
                                        ref={(r) => (tableRowRefs.current[`${idx}-${key}`] = r)}
                                        onClick={(e) => handleRowClick(e, key)}
                                    >
                                        <div className={cx(tableDataRowStyles, styles.wCheck)} />

                                        <div className={cx(tableDataRowStyles, styles.w100)}>
                                            {key + 1}
                                        </div>

                                        <div className="flex">
                                            <div
                                                className={cx(
                                                    tableDataRowStyles,
                                                    styles.w40,
                                                    'z-[1]',
                                                    'sticky left-2'
                                                )}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {isEditing === `${idx}${key}` ? (
                                                    <div className="flex justify-between pt-1.5">
                                                        <div
                                                            className={cx(
                                                                styles.icon,
                                                                ICON_CANCEL,
                                                                `text-white [&&]:bg-slate-700`
                                                            )}
                                                            onClick={handleCancel}
                                                        />
                                                        <div
                                                            className={cx(
                                                                styles.icon,
                                                                ICON_SAVE,
                                                                `text-white [&&]:bg-slate-800`
                                                            )}
                                                            onClick={handleSave}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={cx(
                                                            styles.icon,
                                                            ICON_PENCIL,
                                                            'text-[#333]'
                                                        )}
                                                        onClick={(e) =>
                                                            handleEdit(e, `${idx}${key}`)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            {isEditing === `${idx}${key}`
                                                ? renderInput(lead, idx, key)
                                                : renderText(lead)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}

            {showUpdateModal && (
                <DeleteModal
                    handleDelete={handleUpdate}
                    title={'Would you like to update this contact?'}
                    desc={'Updating will replace the current contact information permanently.'}
                    setIsOpen={() => {
                        setShowUpdateModal(false);
                    }}
                    yesTitle={'Update'}
                />
            )}
        </div>
    );
};

export default React.memo(GroupedTableData);
