import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//styles
import { cssStyles } from './ContactListStyles';
import Icomoon from 'src/assets/Icomoon';

// apis
import { useCreateEmailMutation, useLazyInitHubspotQuery } from 'src/redux/queries/Hubspot';

// widgets
import { ColorRing } from 'react-loader-spinner';
import { toast } from 'react-toastify';

// hooks
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import { formatMarkdown, openUrl } from 'src/shared/utils';

interface IContacts {
    name: string;
    email: string;
    id: string;
    selected?: Boolean;
}

interface IProps {
    handleClose: VoidFunction;
    type: string;
    data: string;
}

const ContactList = ({ handleClose, data, type }: IProps) => {
    const [initHubspot] = useLazyInitHubspotQuery();
    const [createEmail] = useCreateEmailMutation();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [contacts, setContacts] = useState<IContacts[]>([]);

    const loc = useLocation();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        fetchContacts();

        return () => {
            document.body.style.overflow = '';
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchContacts = async () => {
        try {
            setIsLoading(true);
            await initHubspot(null)
                .unwrap()
                .then((data) => {
                    if (data?.loginUrl) {
                        localStorage.setItem('hubspot_redirect', loc.pathname);
                        openUrl(data.loginUrl);
                    } else if (data?.data) {
                        setContacts(data?.data || []);
                    }
                })
                .catch((e) => {
                    throw e;
                });
        } catch (error) {
            console.log('error >>>> ', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (id: string) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact) =>
                contact.id === id ? { ...contact, selected: !contact.selected } : contact
            )
        );
    };

    const filteredContacts = contacts.filter(
        (contact) =>
            contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            contact.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const selectedContacts = contacts.filter((contact) => contact.selected);
    const selectedContactsCount = selectedContacts.length;

    const renderLoader = () => {
        return (
            <div className={cssStyles.loader}>
                <ColorRing
                    height={60}
                    width={60}
                    colors={['#004170', '#004170', '#004170', '#004170', '#004170']}
                    ariaLabel="loading"
                    visible={true}
                    wrapperClass={cssStyles.loader}
                />
            </div>
        );
    };

    const sendEmail = async () => {
        try {
            dispatch(startLoading());
            await createEmail({ contacts: selectedContacts, data: formatMarkdown(data), type })
                .unwrap()
                .then((data) => {
                    if (data.statusCode === 0) {
                        toast.success('Email sent successfully to hubspot');
                        handleClose();
                        return;
                    }
                    toast.error('Failed to send email!');
                });
        } catch (error) {
            toast.error('Failed to send email!');
            console.log(error);
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <>
            <div className={cssStyles.overlay} />
            <div className={cssStyles.container}>
                <div className={cssStyles.close}>
                    <Icomoon
                        className={cssStyles.closeIcon}
                        icon={'cancel'}
                        onClick={handleClose}
                    />
                </div>
                <div className={cx('flex items-center mb-4')}>
                    <h1 className={cssStyles.title}>Contact List</h1>
                    <Icomoon className="w-5 h-5" icon={'users'} />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search..."
                        className={cssStyles.input}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div className="flex items-center mb-4">
                    <p className="text-gray-600 mr-2">
                        {selectedContactsCount}{' '}
                        {selectedContactsCount === 1 ? 'contact' : 'contacts'} selected
                    </p>
                    <Icomoon className="w-4 h-4" icon={'author'} />
                </div>

                <div className="h-80 overflow-y-auto">
                    {isLoading ? (
                        renderLoader()
                    ) : filteredContacts?.length ? (
                        filteredContacts.map((contact, key) => (
                            <div
                                key={contact.id}
                                className={cx(cssStyles.contact, {
                                    'border-b-0': key === filteredContacts.length - 1
                                })}
                            >
                                <div
                                    className={cx(cssStyles.checkbox, {
                                        [cssStyles.checked]: contact.selected
                                    })}
                                    onClick={() => handleCheckboxChange(contact.id)}
                                >
                                    {!!contact.selected && (
                                        <Icomoon className={cssStyles.tick} icon="accept" />
                                    )}
                                </div>

                                <div>
                                    <h2 className={cssStyles.contactName}>{contact.name}</h2>
                                    <p className={cssStyles.contactEmail}>{contact.email}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className={cx(cssStyles.empty)}>{'No contact found!'}</p>
                    )}
                </div>

                {!!filteredContacts?.length && (
                    <div className={cssStyles.cta}>
                        <button className={cssStyles.ctaBtn}>
                            <Icomoon
                                className={cssStyles.send}
                                icon={'send-mail'}
                                onClick={sendEmail}
                            />
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default React.memo(ContactList);
