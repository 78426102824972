export const CONTACT_HEADINGS = [
    { id: 'firstName', label: 'First Name', enableSort: true, medium: true },
    { id: 'lastName', label: 'Last Name', enableSort: true, medium: true },
    { id: 'email', label: 'Email', enableSort: true },
    { id: 'jobTitle', label: 'Job Title', enableSort: true },
    { id: 'seniorityLevel', label: 'Seniority Level', enableSort: true },
    {
        id: 'departmentFunction',
        label: 'Department Function',
        enableSort: true
    },
    { id: 'jobResponsibilities', label: 'Job Responsibilities' },
    { id: 'companyName', label: 'Company Name', enableSort: true },
    {
        id: 'companyAnnualRevenue',
        label: 'Company Annual Revenue',
        enableSort: true
    },
    {
        id: 'technographicDetails',
        label: 'Technographic Details',
        enableSort: true
    },
    {
        id: 'technologyPlatform',
        label: 'Technology Platform',
        enableSort: true
    },
    {
        id: 'technologyAdoptionStage',
        label: 'Technology Adoption Stage',
        enableSort: true
    },
    {
        id: 'competitorTracking',
        label: 'Competitor Tracking',
        enableSort: true
    },
    {
        id: 'competitiveCoverage',
        label: 'Competitive Coverage',
        enableSort: true
    },
    { id: 'growthInitiatives', label: 'Growth Initiatives', enableSort: true },
    { id: 'keyPriorities', label: 'Key Priorities', enableSort: true },
    {
        id: 'executiveInitiatives',
        label: 'Executive Initiatives',
        enableSort: true
    },
    {
        id: 'productBenefitsAdoption',
        label: 'Product Benefits Adoption',
        enableSort: true
    },
    { id: 'solutionProviders', label: 'Solution Providers', enableSort: true },
    { id: 'useCaseFit', label: 'UseCase Fit', enableSort: true },
    { id: 'industry', label: 'Industry', enableSort: true },
    { id: 'subIndustry', label: 'Sub Industry', enableSort: true },
    {
        id: 'industryUseCaseFit',
        label: 'Industry UseCase Fit',
        enableSort: true
    },
    { id: 'linkedin_bio', label: 'LinkedIn BIO' },
    { id: 'painPoints', label: 'Pain Points' },
    { id: 'industryTrends', label: 'Industry Trends' },
    { id: 'targetingStrategy1', label: 'Targeting Strategy 1' },
    { id: 'targetingStrategy2', label: 'Targeting Strategy 2' },
    { id: 'purchaseTriggers', label: 'Purchase Triggers' },
    { id: 'personInsight', label: 'Insights' },
    { id: 'successMeasures', label: 'Success Measures' },
    { id: 'kloutScore', label: 'Lead Score', enableSort: true, medium: true },
    { id: 'status', label: 'Status', enableSort: true },
    {
        id: 'lastEmailClickDate',
        label: 'Last Email Click Date',
        enableSort: true
    },
    { id: 'lastActivityDate', label: 'Last Activity Date', enableSort: true }
];

export const CONTACT_KEYS = [
    'firstName',
    'lastName',
    'email',
    'jobTitle',
    'seniorityLevel',
    'departmentFunction',
    'jobResponsibilities',
    'companyName',
    'annualRevenue',
    'technographicDetails',
    'technologyPlatform',
    'technologyAdoptionStage',
    'competitorTracking',
    'competitiveCoverage',
    'growthInitiatives',
    'keyPriorities',
    'executiveInitiatives',
    'productBenefitsAdoption',
    'solutionProviders',
    'useCaseFit',
    'industry',
    'subIndustry',
    'industryUseCaseFit',
    'linkedin_bio',
    'painPoints',
    'industryTrends',
    'targetingStrategy1',
    'targetingStrategy2',
    'purchaseTriggers',
    'personInsight',
    'successMeasures',
    'kloutScore',
    'lifecycleState',
    'lastEmailClickDate',
    'lastActivityDate'
];

export const SINGLE_LINE_KEYS = [
    'firstName',
    'lastName',
    'email',
    'jobTitle',
    'seniorityLevel',
    'departmentFunction',
    'kloutScore',
    'lifecycleState',
    'lastEmailClickDate',
    'lastActivityDate',
    'industry',
    'subIndustry'
];
