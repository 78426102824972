import React from 'react';
import cx from 'classnames';

//widgets
import AddMemberCta from 'src/views/Private/Profile/widgets/AddMemberCta';
import { OnboardingForm } from 'src/views/Private/Org/OrgOnboarding/widgets/OnboardingForm';
import BlockGen from './widgets/BlockGen';
import OrgMembersList from './widgets/OrgMembersList';
import ContentList from './widgets/ContentList';
import { CustomButton } from 'src/widgets';

//helpers
import { transformStateData } from 'src/views/Private/Org/OrgOnboarding/helpers';

//assets
import Icomoon from 'src/assets/Icomoon';

//styles
import styles from '../../Tabs.module.scss';

//defs
import { IOrg } from 'src/defs';

interface IProps {
    userOrgs: { role: string; code: string }[];
    orgsData: IOrg[];
    activeOrg: string | number;
    isEditingOrgId: string | number;
    defaultOrg: string | number;
    addMember: () => Promise<void>;
    handleAddNewOrg: () => Promise<void>;
    setActiveOrg: (org: string | number) => void;
    setIsEditingOrgId: (orgId: string | number) => void;
    handleDefaultOrg: (e: React.MouseEvent, orgCode: string | number) => void;
    handleUserRoleChange: (e: React.ChangeEvent, email: string, org: IOrg) => void;
    setDeleteData: (data: { title: string; desc: string }) => void;
    setDeleteId: (data: { type: string; id: string }) => void;
    setShowDeleteModal: (value: boolean) => void;
    handleEditOrgSuccess: (data: IOrg) => void;
}

const OrgsTab = ({
    userOrgs,
    orgsData,
    activeOrg,
    isEditingOrgId,
    defaultOrg,
    addMember,
    handleAddNewOrg,
    setActiveOrg,
    setIsEditingOrgId,
    handleDefaultOrg,
    handleUserRoleChange,
    setDeleteData,
    setDeleteId,
    setShowDeleteModal,
    handleEditOrgSuccess
}: IProps) => {
    return (
        <div className={cx('')}>
            <div className={cx('flex justify-end')}>
                <h1
                    className={cx(
                        'py-1 text-white px-4 my-2 bg-blue-600 shadow rounded-xl shadow w-32 text-center cursor-pointer',
                        'hover:bg-blue-700 font-bold'
                    )}
                    onClick={handleAddNewOrg}
                >
                    {'Add Org'}
                </h1>
            </div>
            {orgsData?.map((org, key) => {
                const isAdmin = userOrgs.find((uo) => uo.role === 'admin' && uo.code === org.code);
                const isActiveOrg = activeOrg === org.code;
                const isEditActive = isEditingOrgId === org?.code;
                const isDefaultOrg = defaultOrg === org?.code;
                return (
                    <div key={key} className={cx('')}>
                        <div
                            className={cx(
                                'flex items-center justify-between text-lg bg-slate-100 p-2 my-2 rounded  cursor-pointer hover:bg-slate-300',
                                { 'bg-slate-300': isActiveOrg },
                                { 'bg-slate-400': isDefaultOrg }
                            )}
                            onClick={() => {
                                if (isEditActive) return;
                                setActiveOrg(isActiveOrg ? '' : org.code);
                                setIsEditingOrgId('');
                            }}
                        >
                            <div>
                                {org?.name}{' '}
                                {isDefaultOrg && (
                                    <Icomoon
                                        className={cx('h-2 mr-2', styles.edit)}
                                        icon={'badge'}
                                    />
                                )}
                            </div>

                            {isAdmin && isActiveOrg ? (
                                !isEditActive ? (
                                    <Icomoon
                                        className={styles.edit}
                                        icon={'pencil'}
                                        onClick={(
                                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                                        ) => {
                                            setIsEditingOrgId(org.code);
                                            e.stopPropagation();
                                        }}
                                    />
                                ) : (
                                    <Icomoon
                                        className={styles.edit}
                                        icon={'cancel'}
                                        onClick={(
                                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                                        ) => {
                                            setIsEditingOrgId('');
                                            e.stopPropagation();
                                        }}
                                    />
                                )
                            ) : null}
                        </div>
                        {activeOrg === org?.code && (
                            <>
                                <div className={cx('mx-4 mt-4 flex')}>
                                    {isAdmin && (
                                        <div className={cx('mr-4')}>
                                            <AddMemberCta
                                                handleAddMember={addMember}
                                                ctaClass={cx(
                                                    'py-1 text-white text-sm px-4 bg-blue-600 shadow rounded-xl shadow w-32 text-center cursor-pointer',
                                                    'hover:bg-blue-700 font-bold'
                                                )}
                                            />
                                        </div>
                                    )}
                                    {!isDefaultOrg && (
                                        <CustomButton
                                            title="Make Default"
                                            onClick={(event: React.MouseEvent<HTMLElement>) =>
                                                handleDefaultOrg(event, org?.code)
                                            }
                                            className={cx(
                                                'p-1 text-sm font-bold shadow px-4 bg-blue-600 rounded-xl'
                                            )}
                                        />
                                    )}
                                    <BlockGen orgName={org.name} orgId={org.code} />
                                </div>
                                <div className={cx('w-full')}>
                                    <OnboardingForm
                                        data={{ ...org, ...transformStateData(org) }}
                                        isViewing={!isEditActive}
                                        isEditing={isEditActive}
                                        inputClass={styles.input}
                                        handleSuccess={handleEditOrgSuccess}
                                        isDefaultOrg={isDefaultOrg}
                                    />
                                </div>
                                <ContentList org={org} />
                                <OrgMembersList
                                    org={org}
                                    isAdmin={!!isAdmin}
                                    handleUserRoleChange={handleUserRoleChange}
                                    setDeleteData={setDeleteData}
                                    setDeleteId={setDeleteId}
                                    setShowDeleteModal={setShowDeleteModal}
                                />
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(OrgsTab);
