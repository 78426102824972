// export const CASE_STUDIES = [
//     `
// ### **Case Study 1: Bramasol**
// **Company Description:**
// The Leader in Finance Transformation and Compliance, specializing in areas like Lease Accounting,
// Financial Statement Close, and Disclosure Reporting.
// **Founded:** *Not specified*
// **Problem Statements:** Discovery needed an automated lease accounting disclosure
// solution that was compliant with ASC 842/IFRS 16. This solution needed to fit their
// existing processes to enable efficient financial statement close.
// **Customer Requirements:** Automated lease accounting disclosure solution, Compliance with ASC
// 842/IFRS 16, Fit existing processes
// **Key Challenges:** Centralized disclosure reporting that includes countries with disconnected systems. Security concerns over
// updates to configuration and manual tables. Difficulty in reconciling disclosure
// with some management reports. Liability forecast follows a rolling 12-month cycle.
// Currency exchange rates are not fully maintained, especially for countries with
// disconnected systems.
// **Solution Overview:** Table to facilitate manual entries of
// lease cost, payment, variable cost, and other categories as source data for
// reporting. Exchange rate triangulation functionality to report on group currency.
// Rolling 12-month cycle feature to Maturity Analysis report. Report details such as
// contract name, asset class, and asset number to FASB/IFRS reports to simplify
// reconciliation. Audit trails to disclosure configuration and manual tables with
// details on changes made.
// **Outcomes:** Bramasol enabled automated lease disclosures,
// efficient completion on time and within budget.
// **Entity Relationships:** Bramasol provided an automated lease accounting solution aligning with ASC 842/IFRS 16 for
// Discovery. Bramasol addressed challenges like disconnected systems and currency
// issues. Implemented features like manual entry tables, audit trails, and maturity
// reports.`,

//     `### **Case Study 2: Bramasol**
// **Company Description:**
// The Leader in Finance Transformation and Compliance, specializing in accounting and reporting
// solutions.
// **Founded:** *Not specified*
// **Problem Statements:** A $9B tier one Automotive supplier needed a solution for complex scheduling, engineering design,
// and volume pricing challenges, as well as system integration with their contract
// repository.
// **Customer Requirements:** Integration with contract repository, Support
// for complex scheduling, engineering design, and volume pricing.
// **Key Challenges:**
// Complex Scheduling Engineering Design and Development Volume Pricing Integration
// with contract repository system
// **Solution Overview:** SAP RAR solution to support
// all accounting and reporting requirements. Custom scenarios and process migration.
// Standard functionality for revenue postings. Successful installation & customization
// of RAR 1.2.
// **Outcomes:** Provided technical and functional resources for on-time
// project delivery.
// **Entity Relationships:** Bramasol partnered to deliver SAP RAR
// solutions to a global automotive supplier. SAP RAR solutions were customized to
// handle complex scheduling and volume pricing. System integration for contract
// repository achieved. `,

//     `### **Case Study 3: Nvidia**
// **Company Description:**
// A leading company known for graphics processing, required compliance with ASC 606 in
// its SAP sales model.
// **Founded:** *Not specified*
// **Problem Statements:** Nvidia had to be compliant with ASC 606 and restructure their sales model within SAP.
// **Customer Requirements:** Compliance with ASC 606, SAP sales model integration.
// **Key Challenges:** Quick implementation of disclosure reporting Lack of interface
// between Vistex and RAR Profitability report limitations Go-live issues Call-off
// order functionality gaps
// **Solution Overview:** Proprietary disclosure reports for
// quick and effective compliance. Seamless integration of Vistex for rebates program.
// Developed functionality to provide profitability reports by RAR dimensions. Enhanced
// RAR functionality for Call-off order fulfillments.
// **Outcomes:** Successful go-live with compliance achieved before the ASC 606 regulatory deadline.
// **Entity Relationships:** Bramasol provided Nvidia solutions for ASC 606 compliance.
// Challenges like integration of Vistex and lack of profitability reporting were
// addressed. Bramasol's partnership involved implementing revenue accounting processes
// within SAP.`,

//     `### **Case Study 4: Nu Skin**
// **Company Description:**
// An American multilevel marketing company focusing on personal care products and dietary
// supplements.
// **Founded:** 1984
// **Problem Statements:** Nuskin had to be compliant
// with ASC 606 and restructure their sales model within SAP.
// **Customer Requirements:** Compliance with ASC 606 Management of Loyalty and Bonus Points
// systems
// **Key Challenges:** Accurate disclosure reporting Recognition of Loyalty and
// Bonus Points over Average Redemption Period
// **Solution Overview:** RAR contracts with distinct POBs for financial requirements. Custom program for contract
// cancellations. Integration into SAP SD for loyalty points management. Accelerated
// disclosure report development.
// **Outcomes:** Enhanced business visibility and competitive advantage through successful compliance.
// **Entity Relationships:** Bramasol developed solutions to manage Nu Skin's loyalty and bonus points within
// SAP. Compliance facilitated through tailored revenue accounting and disclosure
// reports. Nu Skin's transformation viewed as a compliance-driven competitive
// advantage.`
// ];

export const FAQS = [
    { question: `What were the main challenges faced in this case study?` },
    { question: `How was the solution implemented?` },
    { question: `What were the key outcomes of this project?` },
    { question: `How long did the implementation take?` },
    { question: `Were there any unexpected benefits or challenges?` },
    { question: `What specific technologies or tools were used in this project?` },
    { question: `How did the client measure the success of the implementation?` },
    { question: `Were there any regulatory compliance issues to consider?` },
    { question: `How did the solution impact the client's business processes?` },
    { question: `What was the return on investment (ROI) for this project?` },
    { question: `How did the team handle change management during implementation?` },
    { question: `Were there any security considerations in this project?` },
    { question: `How scalable is the implemented solution?` },
    { question: `What kind of ongoing support or maintenance was required?` },
    { question: `How did this project align with the client's long-term business strategy?` }
];

export const CASE_STUDIES = [
    `
### **Case Study: Gigamon and Cegedim.cloud**
**Company Description**
Cegedim.cloud is a division of **Cegedim**, a technology and services company founded in **1969**. The company specializes in digital data flow management for healthcare ecosystems and provides business software solutions for the healthcare and insurance sectors.

**Problem Statements**
Cegedim.cloud faced the following challenges:

- 1.Visibility Limitations: The company required complete visibility into its virtual infrastructure to prevent incidents and uphold service standards. Existing monitoring tools only covered the physical network, leaving the virtual infrastructure unmonitored.
- 2.Traffic Management: There was a need to capture and route traffic from virtual infrastructure to a physical metrology probe, ensuring consistent monitoring across all data centers.
- 3.High Data Volume: The large volume of data necessitated optimized monitoring performance to prevent overloading.

**Customer Requirements**
- Cegedim.cloud's monitoring needs included:

- 1.Comprehensive Observability: Complete visibility into virtual infrastructure traffic.
- 2.Traffic Aggregation and Deduplication: Aggregation and deduplication of traffic to maintain optimal probe performance.
- 3.Deployment Flexibility: Scalable and flexible infrastructure deployment.
- 4.Extended Visibility: Enhanced monitoring across data centers in **Paris** and **Toulouse**.

**Key Challenges**
- Cegedim.cloud encountered three primary challenges in achieving its goals:

- 1.Achieving Full Visibility: Required for effective incident management in a complex, compartmentalized virtual environment.
- 2.Traffic Volume Reduction: The existing monitoring probe was nearing its capacity limits, necessitating traffic volume reduction.
- 3.Ensuring Compatibility: Maintaining compatibility and alignment between physical and virtual network monitoring tools.

**Solution Overview**
- Cegedim.cloud implemented Gigamon's **Deep Observability Pipeline** to address its needs. This included:

- 1.GigaVUE® Cloud Suite for VMware: Enhanced visibility within the virtual infrastructure.
- 2.GigaVUE HC Series: Offered high-capacity traffic aggregation.
- 3.GigaSMART®: Improved performance through deduplication and tunnel decapsulation.
- 4.GigaVUE-FM Fabric Manager: Simplified network management across all data centers.

**Outcomes**
The deployment of Gigamon's solution brought significant improvements:

- 1.Traffic Reduction: The probe's traffic load was cut by half without compromising visibility.
- 2.Improved Flexibility: Cegedim.cloud gained flexibility in configuring its IT infrastructure, enhancing performance.
- 3.Future Readiness: The solution laid a foundation for Cegedim.cloud to expand its monitoring capabilities and scalability.

## Entity Relationships
- 1.Gigamon's Role: Gigamon provided a comprehensive observability solution that improved Cegedim.cloud's infrastructure monitoring and visibility.
- 2.Cegedim.cloud's Leverage: By unifying physical and virtual traffic data through Gigamon's tools, Cegedim.cloud reduced the monitoring probe's load and strengthened its incident response capabilities.
`,
    `### **Case Study: Gigamon and Large Diversified Retail, Banking, and Finance Company**

**Company Description**
- A diversified company based in **Canada**, with over **1,100 retail stores**, gas stations, a financial services provider, and a federally chartered bank.

**Problem Statements**
- The company faced several challenges with its existing network monitoring setup:

- 1.High Complexity and Cost: Required a cost-effective way to monitor a large, distributed network. The existing setup involved multiple probes and sniffers, each necessitating manual connection changes, which was costly, complex, and time-intensive.
- 2.Security Requirements: Needed secure traffic monitoring due to the sensitivity of financial data and compliance requirements.

**Customer Requirements**
- The company's monitoring requirements included:

- 1.Flexible and Secure Network Traffic Monitoring**: Flexible monitoring across multiple tools with secure access.
- 2.Cost-Effective Traffic Aggregation**: Efficient traffic aggregation to reduce the number of required probes and sniffers.
- 3.Remote and Secure Access**: Capability for remote and secure access to network traffic for troubleshooting.

**Key Challenges**
- The company encountered key challenges in optimizing its network monitoring:

- 1.High Costs of Deployment: Needed numerous probes for each network segment, increasing costs.
- 2.Operational Inefficiency and Security Risks: Manual reconnections of monitoring tools were inefficient and introduced potential security vulnerabilities.
- 3.Compliance Concerns: Unsecured TAP monitor ports for financial data were a compliance risk.

**Solution Overview**
- The company implemented Gigamon's **GigaVUE® Traffic Visibility Node**, which provided:

- 1.Traffic Aggregation: Aggregated traffic from multiple TAPs, reducing the need for numerous probes.
- 2.Remote Access for Monitoring Tools: Enabled remote connection and disconnection of monitoring tools without physical handling.
- 3.Centralized, Secure Monitoring: Created a centralized and secure setup for improved compliance with financial data regulations.

**Outcomes**
- By deploying **GigaVUE**, the company achieved substantial improvements:

- 1.Cost Savings**: Reduced probe requirements by up to **87%**, saving approximately **$120,000** in upfront costs.
- 1.Enhanced Uptime, Performance, and Security**: Enabled centralized aggregation and filtering of traffic, enhancing compliance and reducing operational overhead.

**Entity Relationships**
- 1.Gigamon's Role: Gigamon provided the **GigaVUE Traffic Visibility Node**, streamlining the company's network monitoring.
- 2.Company's Benefits: The retail, banking, and finance company gained a secure, flexible, and cost-efficient network monitoring solution, improving compliance and operational efficiency.
 `,
    `### **Case Study: Gigamon and the Department of Defense**

**Company Description**
- The **Department of Defense (DoD)** is focused on enhancing network security, particularly through a **Zero Trust Architecture** that spans its physical, virtual, and cloud environments.

**Problem Statements**
- In a **2018 assessment**, the DoD identified several critical network security vulnerabilities:

- 1.Lateral Movement: Unauthorized access within the network was possible once an adversary breached the perimeter.
- 2.Privilege Escalation: Adversaries could access sensitive areas without the necessary permissions.
- 3.Lack of Comprehensive Visibility: Insufficient insight across physical, virtual, and cloud infrastructure limited proactive threat detection and mitigation.

**Customer Requirements**
- To address these vulnerabilities, the DoD required:

- 1.Full Network Visibility: Comprehensive monitoring to support Zero Trust Architecture and prevent unauthorized access.
- 2.Enhanced Packet Inspection: Granular security monitoring to detect suspicious activities.
- 3.Integrated Tools: Efficient tools to manage both security and network operations effectively.

**Key Challenges**
- The DoD faced several challenges in achieving its security goals:

- 1.Ensuring Robust Visibility: Needed comprehensive visibility across a diverse IT landscape (physical, virtual, and cloud) to detect potential threats.
- 2.Reducing Data Noise: Required a reduction in unnecessary data to enhance tool efficiency.
- 3.Scalability: Needed a scalable solution to support current and future network environments.

**Solution Overview**
- The DoD implemented Gigamon's **Deep Observability Pipeline** to enhance network security. The deployment included:

- 1.GigaVUE-FM (Fabric Manager), GigaVUE V Series, GigaVUE HC Series, and GigaVUE TA Series**: These tools provided pervasive visibility across all network layers.
- 2.GigaSMART® Application Metadata Intelligence and Packet De-Duplication**: Reduced redundant data and streamlined packet inspection.
- 3.GigaVUE OS: Centralized control over data aggregation and distribution to security tools, improving efficiency.

**Outcomes**
- Gigamon's solutions provided the DoD with a **unified, efficient view of network activity**, essential for the Zero Trust framework. Key benefits included:

- 1.Enhanced Tool Performance: By reducing data noise and providing targeted packet information, tool efficiency improved, enabling more effective security monitoring.
- 2.Cloud Compatibility: Gigamon's deep observability platform extended seamlessly to cloud environments, aligning with DoD's security requirements and supporting future growth.

**Entity Relationships**
- 1.Gigamon's Contribution: Gigamon provided critical observability tools, enabling the DoD to implement an effective Zero Trust model.
- 2.DoD's Leverage: The Department of Defense utilized Gigamon's solutions to gain visibility, improve security resilience, and reduce operational costs through efficient network monitoring.
`,
    `### **Case Study: Gigamon and Corpay**

**Company Description**
- **Corpay**, headquartered in **Atlanta, Georgia**, is a global financial technology company specializing in corporate spend management. Corpay helps organizations control business expenses, employee spending, and accounts payable processes.

**Problem Statements**
- Corpay faced challenges in securing its extensive multi-cloud environment, which included **AWS, Azure, and Google Cloud Platform**. The company needed to enhance visibility across these platforms to proactively identify and mitigate threats, ensuring the security of sensitive financial data.

**Customer Requirements**
- Corpay's specific requirements included:

- 1.Comprehensive visibility** into multi-cloud environments.
- 2.Proactive threat identification and mitigation**.
- 3.Integration with existing security tools**.

**Key Challenges**
- The primary challenges faced by Corpay were:

- 1.Lack of unified visibility across diverse cloud platforms.
- 2.Difficulty in detecting and responding to threats in real-time.
- 3.Ensuring compliance with financial industry regulations.

**Solution Overview**
- Corpay implemented Gigamon's **Deep Observability Pipeline**, which provided:

- 1.Unified visibility** across AWS, Azure, and Google Cloud Platform.
- 2.Integration with existing security tools** for enhanced threat detection.
- 3.Advanced analytics to identify and mitigate threats proactively.

**Outcomes**
- With Gigamon's solution, Corpay achieved comprehensive visibility into its multi-cloud environment, enabling proactive threat identification and mitigation. This enhanced security posture ensured the protection of sensitive financial data and compliance with industry regulations.

**Entity Relationships**
- 1.Gigamon's Role: Gigamon provided the Deep Observability Pipeline, enhancing Corpay's security across multi-cloud platforms.
- 2.Corpay's Benefits: Corpay leveraged Gigamon's solution to secure its financial technology services, ensuring data protection and regulatory compliance.

`,
    `### **Case Study: Gigamon and the University of Glasgow**

**Company Description**
- The **University of Glasgow**, established in **1451**, is a leading research-intensive institution in Scotland, serving over **30,000 students and staff**.

**Problem Statements**
- The university needed to scale its intrusion detection system (**IDS**) to support a growing number of users and devices. Existing solutions lacked the capacity to handle increased network traffic, leading to potential security blind spots.

**Customer Requirements**
- The university's specific requirements included:

- 1.Scalable IDS to accommodate network growth.
- 2.Comprehensive visibility into network traffic.
- 3.Integration with existing security infrastructure.

**Key Challenges**
- The main challenges faced by the University of Glasgow were:

- 1.Handling increased network traffic without compromising security.
- 2.Ensuring real-time threat detection and response.
- 3.Maintaining compliance with data protection regulations.

**Solution Overview**
- The University of Glasgow deployed Gigamon's **Deep Observability Pipeline**, which included:

- 1.Scalable visibility solutions to handle increased network traffic.
- 2.Integration with existing IDS tools for enhanced threat detection.
- 3.Advanced analytics to monitor and analyze network traffic in real-time.

**Outcomes**
- Gigamon's solution enabled the university to scale its IDS effectively, providing comprehensive visibility into network traffic and enhancing its ability to detect and respond to threats in real-time. This ensured the security of sensitive academic and personal data.

**Entity Relationships**
- 1.Gigamon's Role: Gigamon provided scalable visibility solutions, enhancing the university's network security infrastructure.
- 2.University's Benefits: The University of Glasgow utilized Gigamon's solutions to secure its network, protecting sensitive data and supporting its academic mission.
`
];
