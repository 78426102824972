import React from 'react';
import cx from 'classnames';

//defs
import type { IContentFormData, IPreset } from 'src/defs';

//styles
import styles from './Sidebar.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';
import { CONTENT_FILE_TYPES, PERSONA_TONE_OPTIONS } from 'src/shared/constants';
import {
    APPLY,
    CONTENT_FILTERS,
    CONTENT_FILTERS_OPTIONS,
    EMOTIONAL_FILTERS,
    EMOTIONAL_FILTERS_OPTIONS,
    MY_PRESETS,
    PERSONA_FILTERS,
    PERSONA_FILTERS_OPTIONS,
    SAVE_AS_PRESET
} from './constants';
import { ICON_SAVE } from 'src/shared/constants/icons';

//widgets
import { RadioTitle } from '../RadioTitle';
import { CheckTitle } from '../CheckTitle';
import { CustomButton, CustomInput } from 'src/widgets';

//helpers
import { useSelector } from 'react-redux';

interface IProps {
    data: IContentFormData;
    errors: { campaignOrg?: string };
    presets: IPreset[];
    applyPreset: (preset: IPreset) => void;
    setContentInputType: (type: string) => void;
    handleToneChange: (tone: { value: string }) => void;
    handleEmotionalFilterChange: (emotionalFilter: { value: string }) => void;
    handleContentFilterChange: (contentFilter: { value: string }) => void;
    handlePersonaFilterChange: (personaFilter: { value: string }) => void;
    handleOrgUpdate: (e: any) => void;
    handeSavePreset: VoidFunction;
}

const Sidebar = ({
    data,
    errors,
    presets,
    applyPreset,
    setContentInputType,
    handleToneChange,
    handleOrgUpdate,
    handleEmotionalFilterChange,
    handleContentFilterChange,
    handlePersonaFilterChange,
    handeSavePreset
}: IProps) => {
    const userOrgs = useSelector((state: any) => state.user.userDetails?.orgs) || [];

    return (
        <div className={styles.sidebar}>
            <CheckTitle
                heading={STRINGS.IMPORT_CONTENT}
                headingTooltip={STRINGS.IMPORT_CONTENT_TOOLTIP}
                data={Object.values(CONTENT_FILE_TYPES)}
                checked={data.file}
                onSelect={setContentInputType}
            />

            <RadioTitle
                heading={STRINGS.TONE_REGISTER_TYPE}
                headingTooltip={STRINGS.TONE_REGISTER_TYPE_TOOLTIP}
                data={PERSONA_TONE_OPTIONS}
                value={data.toneRegister}
                onSelect={handleToneChange}
            />

            <RadioTitle
                heading={EMOTIONAL_FILTERS}
                data={EMOTIONAL_FILTERS_OPTIONS}
                value={data.emotionalFilter}
                onSelect={handleEmotionalFilterChange}
            />

            <RadioTitle
                heading={CONTENT_FILTERS}
                data={CONTENT_FILTERS_OPTIONS}
                value={data.contentFilter}
                onSelect={handleContentFilterChange}
            />

            <RadioTitle
                heading={PERSONA_FILTERS}
                data={PERSONA_FILTERS_OPTIONS}
                value={data.personaFilter}
                onSelect={handlePersonaFilterChange}
            />

            <CustomInput
                id={'campaignOrg'}
                label={STRINGS.ORG}
                inputClass={styles.mainInput}
                value={{ label: data.orgName || '', value: data.orgCode || '' }}
                onChange={handleOrgUpdate}
                error={errors.campaignOrg}
                inputType={'select'}
                labelClass={styles.label}
                selectOptions={[
                    ...userOrgs.map((org: { name: string; code: string }) => ({
                        label: org.name,
                        value: org.code
                    }))
                ]}
                placement={'bottom'}
                isMulti={false}
            />

            <CustomButton
                title={SAVE_AS_PRESET}
                onClick={handeSavePreset}
                icon={cx(ICON_SAVE, 'mx-2')}
                className={cx('my-2', 'w-[100%]')}
            />

            {!!presets.length && (
                <div>
                    <h1 className={cx(styles.label)}>{MY_PRESETS}</h1>
                    {presets.map((preset: IPreset, idx) => {
                        return (
                            <div key={idx} className={cx('flex items-center justify-between')}>
                                <div className={cx('flex items-center')}>
                                    <div className="mr-2">{idx + 1}.</div>
                                    <div>{preset.presetName}</div>
                                </div>
                                <div
                                    className={cx(
                                        'cursor-pointer',
                                        'hover:bg-slate-200',
                                        'bg-slate-100',
                                        'py-1 px-2',
                                        'rounded',
                                        'text-sm',
                                        'font-medium'
                                    )}
                                    onClick={() => applyPreset(preset)}
                                >
                                    {APPLY}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default React.memo(Sidebar);
