export const BDR_CADENCE_OPTIONS = [
    {
        label: 'Cold Lead',
        value: 'bdr_coldLead'
    },
    {
        label: 'Warm Lead',
        value: 'bdr_warmLead'
    },
    {
        label: 'Engaged Lead',
        value: 'bdr_engagedLead'
    },
    {
        label: 'Custom Combined Templates',
        value: 'bdr_customCombinedTemplates'
    },
    {
        label: 'Custom Template 1',
        value: 'bdr_customTemplate_1'
    },
    {
        label: 'Custom Template 2',
        value: 'bdr_customTemplate_2'
    },
    {
        label: 'Custom Template 3',
        value: 'bdr_customTemplate_3'
    }
];

export const TONE_FILTER = `Tone: {0}`;
export const EMOTIONAL_FILTER = `Emotional: {0}`;
export const CONTENT_FILTER = `Content: {0}`;
export const PERSONA_FILTER = `Persona: {0}`;
export const FILTERS = 'Filters';
