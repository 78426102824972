import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//apis
import { useLazyGetUserQuery, useLazyLogoutQuery } from 'src/redux/queries';

//helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import { clearStore } from 'src/redux/reducers/userReducer';
import { toast } from 'react-toastify';

//constants
import { LABELS, STRINGS } from 'src/shared/strings';

//assets
import DEFAULT_USER from 'src/assets/icons/user.svg';

//components
import { CustomButton } from 'src/widgets';

//styles
import styles from './styles.module.scss';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

//widgets
import HubspotWidget from './widgets/HubspotWidget';
import LinkedinWidget from './widgets/LinkedinWidget';

const Profile = () => {
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState({});

    const [logout] = useLazyLogoutQuery();
    const [getUser] = useLazyGetUserQuery();

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        try {
            dispatch(startLoading());
            await Promise.all([fetchUserData()]);
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(stopLoading());
        }
    };

    const fetchUserData = useCallback(
        async () => {
            if (userDetails?.email) return;
            await getUser()
                .unwrap()
                .then((data) => {
                    setUserDetails(data.data.user);
                })
                .catch((err) => toast.error(err?.data?.message));
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [userDetails]
    );

    const handleLogout = useCallback(
        () => {
            dispatch(clearStore(null));
            analytics.sendClickEvent(
                'logout_click',
                ANALYTICS.SCREEN_NAMES.USER_PROFILE,
                ANALYTICS.ACTIONS.LOGOUT
            );
            localStorage.clear();
            logout();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div className={styles.profile}>
            <div className={styles.user}>
                <div className={styles.userImage}>
                    <img
                        className={styles.userImageImg}
                        src={userDetails?.picture || DEFAULT_USER}
                        alt=""
                    />
                </div>

                {/* {userDetails?.role === USER_ROLES.ADMIN && (
                    <AddMemberCta handleAddMember={handleAddMember} className={styles.userAdd} />
                )} */}

                <div className={styles.userInfo}>
                    <div>
                        <div className={styles.userInfoText}>{LABELS.NAME}</div>
                        <div className={styles.userInfoText}>{userDetails?.name}</div>
                    </div>
                    <div>
                        <div className={styles.userInfoText}>{LABELS.EMAIL}</div>
                        <div className={styles.userInfoText}>{userDetails?.email}</div>
                    </div>
                    {/* <div>
                        <div className={styles.userInfoText}>{STRINGS.ORG}</div>
                        <div className={styles.userInfoText}>{userOrg?.name || STRINGS.EMPTY}</div>
                    </div>
                    <div>
                        <div className={styles.userInfoText}>{STRINGS.ROLE}</div>
                        <div className={styles.userInfoText}>
                            {userDetails?.role || STRINGS.EMPTY}
                        </div>
                    </div>
                    <div>
                        <div className={styles.userInfoText}>{STRINGS.MEMBERS}</div>
                        <div className={styles.userInfoText}>{userOrg?.users || STRINGS.EMPTY}</div>
                    </div> */}
                </div>

                <CustomButton
                    className={styles.userLogout}
                    title={STRINGS.LOGOUT}
                    onClick={handleLogout}
                />
            </div>

            <div className={styles.details}>
                <div className={styles.detailsSidebar}>
                    {/* <div className={styles.detailsTitle}>{STRINGS.DOCS}</div>
                    <div className={styles.detailsSidebarList}>{renderSideBar()}</div> */}
                </div>
                <div className={styles.detailsDocs}>
                    <HubspotWidget />
                    <LinkedinWidget />
                </div>
            </div>
        </div>
    );
};

export default React.memo(Profile);
