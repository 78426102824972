import React, { useEffect, useState } from 'react';
import cx from 'classnames';

// widgets
import ContactsTable from 'src/views/Private/Contacts/widgets/ContactsTable';
import Filters from 'src/views/Private/Contacts/widgets/Filters';

// constants
import { FILTERS } from 'src/views/Private/Contacts/widgets/Filters/constants';

// styles
import styles from './Details.module.scss';

const Details = () => {
    const [selectedFilters, setSelectedFilters] = useState<
        { label: string; value: string; selected?: boolean }[]
    >(() => FILTERS.map((filters) => ({ ...filters, selected: true })));

    const [tableFilters, setTableFilters] = useState(() => FILTERS);

    useEffect(() => {
        setTableFilters(() => selectedFilters.filter((filter) => filter.selected));
    }, [selectedFilters]);

    const handleSelectionChange = (filter: {
        label: string;
        value: string;
        selected?: boolean;
    }) => {
        console.log('Selected values:', filter);

        let selected = selectedFilters.map((filters) => filters);
        let pos = selected.findIndex((selected) => selected.value === filter.value);
        if (pos !== -1) {
            selected.splice(pos, 1, {
                ...filter,
                selected: !filter.selected
            });
        } else {
            selected.splice(pos, 0, {
                ...filter,
                selected: !filter.selected
            });
        }

        setSelectedFilters(selected);
    };

    return (
        <div className={cx(styles.container, 'flex gap-2', 'pt-2 pr-2 pl-3')}>
            {/* <Filters
                items={selectedFilters.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={handleSelectionChange}
            /> */}
            <ContactsTable selectedFilters={tableFilters} />
        </div>
    );
};

export default Details;
