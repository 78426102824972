import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

let token: string = '';

export const apiController = new AbortController();

export const pageReaderApi = createApi({
    reducerPath: 'pageReaderApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/pageReader',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            // Specify RootState to ensure proper typing
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        read: builder.mutation<{ content: string; styles: string }, { url: string }>({
            query: (url) => {
                return {
                    method: 'POST',
                    headers: { ...headers },
                    url: `/`,
                    body: url
                };
            }
        })
    })
});

// Exporting hooks generated by the API
export const { useReadMutation } = pageReaderApi;
