import React from 'react';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';

// styles
import styles from './styles.module.scss';

// constants
import { STRINGS } from 'src/shared/strings';
import { CASE_STUDIES, FAQS } from './constants';

const CaseStudies = () => {
    return (
        <div className={styles.container}>
            <div className={cx('sticky', 'top-0', 'bg-white', 'shadow')}>
                <h1 className={cx('text-xl font-semibold', 'p-4')}>{STRINGS.CASE_STUDIES}</h1>
            </div>

            <div className={cx(styles.caseStudy, 'shadow')}>
                {CASE_STUDIES.map((caseStudy, idx) => (
                    <ReactMarkdown key={idx} className={styles.caseStudySection}>
                        {caseStudy}
                    </ReactMarkdown>
                ))}
            </div>

            <div className={cx(styles.faqs)}>
                <h1 className={cx('text-2xl','font-semibold')}>{'FAQs'}</h1>
                <ul className={cx(styles.faqsList)}>
                    {FAQS.map((faq, idx) => (
                        <li key={idx}>{faq.question}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default React.memo(CaseStudies);
