//libs
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import { PersistConfig } from 'redux-persist/lib/types';

//reducers
import rootreducer from 'src/redux/reducers';
import userReducer from 'src/redux/reducers/userReducer';
import configReducer from 'src/redux/reducers/configReducer';
import taxonomyReducer from 'src/redux/reducers/taxonomy';

//queries
import { usersApi } from 'src/redux/queries/User';
import { authApi } from 'src/redux/queries/Auth';
import { configApi } from 'src/redux/queries/config';
import { documentTypesApi } from 'src/redux/queries/documentTypes';
import { personasApi } from 'src/redux/queries/Personas';
import { promptsApi } from 'src/redux/queries/prompts';
import { campaignsApi } from 'src/redux/queries/Campaigns';
import { orgApi } from 'src/redux/queries/Org';
import { hubspotApi } from 'src/redux/queries/Hubspot';
import { audienceApi } from 'src/redux/queries/Audience';
import { linkedinApi } from 'src/redux/queries/Linkedin';
import { ragApis } from 'src/redux/queries/Rag';
import { embeddingsApis } from 'src/redux/queries/Embeddings';
import { contentApi } from 'src/redux/queries/Content';
import { searchApis } from 'src/redux/queries/Search';
import { clapApi } from 'src/redux/queries/Clap';
import { mappingApi } from 'src/redux/queries/Mappings';
import { logsApi } from 'src/redux/queries/Logs';
import { leadsApi } from 'src/redux/queries/Leads';
import { pageReaderApi } from 'src/redux/queries/PageReader';
import { presetsApi } from 'src/redux/queries/Presets';
import { fbApi } from 'src/redux/queries/Fb';

// Define the structure of the root state
export type RootState = {
    root: ReturnType<typeof rootreducer>;
    user: ReturnType<typeof userReducer>;
    config: ReturnType<typeof configReducer>;
    taxonomy: ReturnType<typeof taxonomyReducer>;
    [authApi.reducerPath]: ReturnType<typeof authApi.reducer>;
    [usersApi.reducerPath]: ReturnType<typeof usersApi.reducer>;
    [configApi.reducerPath]: ReturnType<typeof configApi.reducer>;
    [promptsApi.reducerPath]: ReturnType<typeof promptsApi.reducer>;
    [personasApi.reducerPath]: ReturnType<typeof personasApi.reducer>;
    [documentTypesApi.reducerPath]: ReturnType<typeof documentTypesApi.reducer>;
    [campaignsApi.reducerPath]: ReturnType<typeof campaignsApi.reducer>;
    [orgApi.reducerPath]: ReturnType<typeof orgApi.reducer>;
    [hubspotApi.reducerPath]: ReturnType<typeof hubspotApi.reducer>;
    [audienceApi.reducerPath]: ReturnType<typeof audienceApi.reducer>;
    [linkedinApi.reducerPath]: ReturnType<typeof linkedinApi.reducer>;
    [ragApis.reducerPath]: ReturnType<typeof ragApis.reducer>;
    [embeddingsApis.reducerPath]: ReturnType<typeof embeddingsApis.reducer>;
    [contentApi.reducerPath]: ReturnType<typeof contentApi.reducer>;
    [searchApis.reducerPath]: ReturnType<typeof searchApis.reducer>;
    [clapApi.reducerPath]: ReturnType<typeof clapApi.reducer>;
    [mappingApi.reducerPath]: ReturnType<typeof mappingApi.reducer>;
    [logsApi.reducerPath]: ReturnType<typeof logsApi.reducer>;
    [leadsApi.reducerPath]: ReturnType<typeof leadsApi.reducer>;
    [pageReaderApi.reducerPath]: ReturnType<typeof pageReaderApi.reducer>;
    [presetsApi.reducerPath]: ReturnType<typeof presetsApi.reducer>;
    [fbApi.reducerPath]: ReturnType<typeof fbApi.reducer>;
};

// Persist config type without using 'any'
type CustomPersistConfig = PersistConfig<RootState> & {
    blacklist: (keyof RootState)[];
};

const middlewares = [
    authApi.middleware,
    usersApi.middleware,
    configApi.middleware,
    promptsApi.middleware,
    personasApi.middleware,
    documentTypesApi.middleware,
    campaignsApi.middleware,
    orgApi.middleware,
    hubspotApi.middleware,
    audienceApi.middleware,
    linkedinApi.middleware,
    ragApis.middleware,
    embeddingsApis.middleware,
    contentApi.middleware,
    searchApis.middleware,
    clapApi.middleware,
    mappingApi.middleware,
    logsApi.middleware,
    leadsApi.middleware,
    pageReaderApi.middleware,
    presetsApi.middleware,
    fbApi.middleware
];

const persistConfig: CustomPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'authApi',
        'usersApi',
        'root',
        'configApi',
        'promptsApi',
        'personasApi',
        'documentTypesApi',
        'campaignsApi',
        'orgApi',
        'hubspotApi',
        'audienceApi',
        'linkedinApi',
        'ragApis',
        'embeddingsApis',
        'contentApi',
        'searchApis',
        'clapApi',
        'mappingApi',
        'logsApi',
        'leadsApi',
        'pageReaderApi',
        'presetsApi',
        'fbApi'
    ]
};

const combinedReducer = combineReducers({
    root: rootreducer,
    user: userReducer,
    config: configReducer,
    taxonomy: taxonomyReducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [promptsApi.reducerPath]: promptsApi.reducer,
    [personasApi.reducerPath]: personasApi.reducer,
    [documentTypesApi.reducerPath]: documentTypesApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [orgApi.reducerPath]: orgApi.reducer,
    [hubspotApi.reducerPath]: hubspotApi.reducer,
    [audienceApi.reducerPath]: audienceApi.reducer,
    [linkedinApi.reducerPath]: linkedinApi.reducer,
    [ragApis.reducerPath]: ragApis.reducer,
    [embeddingsApis.reducerPath]: embeddingsApis.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [searchApis.reducerPath]: searchApis.reducer,
    [clapApi.reducerPath]: clapApi.reducer,
    [mappingApi.reducerPath]: mappingApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [leadsApi.reducerPath]: leadsApi.reducer,
    [pageReaderApi.reducerPath]: pageReaderApi.reducer,
    [presetsApi.reducerPath]: presetsApi.reducer,
    [fbApi.reducerPath]: fbApi.reducer
});

const persistedReducer = persistReducer<RootState>(persistConfig, combinedReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(middlewares)
});

export const persistor = persistStore(store);

export default store;

// Define RootState and AppDispatch types for use throughout the app
export type AppDispatch = typeof store.dispatch;
