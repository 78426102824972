import React, { useRef } from 'react';
import cx from 'classnames';

//assets
import Icomoon from 'src/assets/Icomoon';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

//helpers
import { getContentTypeLabel, highlightDifferences } from 'src/shared/utils/helpers';
import { formatMarkdown } from 'src/shared/utils';
import { Zoom, toast } from 'react-toastify';

//widgets
import { CustomButton, CustomTextArea } from 'src/widgets';
import ShareContent from 'src/components/ShareContent';

//styles
import styles from './styles.module.scss';

//defs
import { IRegeneratedChoice } from '../../ResultTab';

//constants
import { LABELS } from 'src/shared/strings';

//apis
import { useLazyMergeQuery } from 'src/redux/queries/Clap';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';

interface IProps {
    choice: { choices: string[]; type: string };
    targetKey: number;
    pos: number;
    currentTrends?: string;
    regeneratedChoices: IRegeneratedChoice;
    cancelEdit: VoidFunction;
    updateResult: (
        value: string | string[],
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        saveData?: boolean,
        isMerge?: boolean
    ) => void;
    updateRegenChoices: (value: string, targetKey: number, pos: number, idx: number) => void;
    clearCurrentTrends: VoidFunction;
    updateTrends: (data: string) => void;
    isEditable: boolean;
    setIsEditable: (value: boolean) => void;
}

const TextClass = cx(
    styles.listText,
    'rounded-xl shadow border w-[50%] bg-white w-full break-words'
);

const ListData = ({
    choice,
    targetKey,
    pos,
    currentTrends,
    regeneratedChoices,
    isEditable,
    cancelEdit = () => {},
    updateRegenChoices,
    updateResult = () => {},
    clearCurrentTrends,
    updateTrends,
    setIsEditable
}: IProps) => {
    const textref = useRef<HTMLDivElement>(null);
    const [merge] = useLazyMergeQuery();

    const dispatch = useDispatch();

    const renderIcons = (isRegenerated = false) => {
        return (
            <Icomoon
                className={styles.iconsPencil}
                icon={'pencil'}
                onClick={() => {
                    if (isRegenerated) {
                        toast.info('Please Accept one choice to start editing', {
                            toastId: 1,
                            transition: Zoom
                        });
                        return;
                    }
                    setIsEditable(!isEditable);
                    analytics.sendClickEvent(
                        'edit_result_click',
                        ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                        ANALYTICS.ACTIONS.EDIT_CHOICE
                    );
                }}
            />
        );
    };

    const acceptChoiceCta = (
        value: string | string[],
        pos: number,
        idx: number,
        type: string,
        title = LABELS.ACCEPT
    ) => {
        return (
            <CustomButton
                gradient
                title={title}
                onClick={() => {
                    updateResult(value, targetKey, pos, idx, type, true);
                }}
            />
        );
    };

    const handleMergeCta = async (
        value: string | string[],
        pos: number,
        idx: number,
        type: string
    ) => {
        if (isEditable) {
            toast.info('Please save and close edit mode first before merging!');
            return;
        }

        if (typeof value !== 'string') {
            value.splice(
                value.length - 1,
                1,
                value[value.length - 1] + '<br /> <br />' + currentTrends
            );
            updateResult(value, targetKey, pos, idx, type, true, true);
            return;
        }

        if (!value || !currentTrends) return;

        try {
            dispatch(startLoading());

            await merge({ data: value, ct: currentTrends })
                .unwrap()
                .then((data) => {
                    console.log('data>>>> ', data?.data);
                    if (data?.data?.length) {
                        updateResult(data?.data, targetKey, pos, idx, type, true, true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error('Error in merging data');
                });
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    const clearCta = () => {
        return (
            <CustomButton
                className="mx-4"
                gradient
                title={LABELS.CLEAR}
                onClick={clearCurrentTrends}
            />
        );
    };

    const renderTextBox = (
        content: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        noClap?: boolean,
        onRegenChange?: (value: string, targetKey: number, pos: number, idx: number) => void
    ) => {
        return (
            <CustomTextArea
                value={content}
                showCount={false}
                onChange={(value: string) =>
                    onRegenChange
                        ? onRegenChange(value, targetKey, pos, idx)
                        : updateResult(value, targetKey, pos, idx, type)
                }
                noClap={noClap}
            />
        );
    };

    const renderBulletList = (list: string[], targetKey: number, pos: number, type: string) => {
        let rgeneratedList = regeneratedChoices?.choices || [];

        return (
            <div className={'flex felx-wrap justify-between'}>
                {renderList(list, targetKey, pos, type)}
                {!!rgeneratedList?.length &&
                    renderList(rgeneratedList, targetKey, pos, type, true, updateRegenChoices)}
                {currentTrends && (
                    <div className={'w-[48%] px-4 flex flex-col justify-between'}>
                        {isEditable ? (
                            <CustomTextArea
                                value={formatMarkdown(currentTrends)}
                                showCount={false}
                                className={styles.listItemContainerWrap}
                                onChange={(value: string) => updateTrends(value)}
                                noClap
                            />
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: formatMarkdown(formatMarkdown(currentTrends))
                                }}
                                className={TextClass}
                            />
                        )}

                        <div className={cx('flex justify-center', 'my-2')}>
                            {clearCta()}
                            <CustomButton
                                gradient
                                title={LABELS.MERGE}
                                onClick={() => handleMergeCta(list, pos, 0, type)}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderList = (
        list: string[],
        targetKey: number,
        pos: number,
        type: string,
        noClap?: boolean,
        onRegenChange?: (value: string, targetKey: number, pos: number, idx: number) => void
    ) => {
        let str1 = '',
            str2 = '';
        return (
            <div className={styles.listContainer}>
                <ul className={styles.list}>
                    {list?.map((item, idx: number) => {
                        if (!idx) {
                            str1 = item;
                        } else {
                            str2 = item;
                            str2 = highlightDifferences(str1, str2, styles.diff);
                        }

                        return (
                            <div
                                className={cx(styles.listDataItem, 'flex justify-between')}
                                key={idx}
                                style={{ minHeight: textref?.current?.offsetHeight }}
                            >
                                {isEditable ? (
                                    renderTextBox(
                                        item,
                                        targetKey,
                                        pos,
                                        idx,
                                        type,
                                        noClap,
                                        onRegenChange
                                    )
                                ) : (
                                    <div style={{ display: 'flex' }}>
                                        <div
                                            className={TextClass}
                                            ref={textref}
                                            dangerouslySetInnerHTML={{
                                                __html: idx
                                                    ? formatMarkdown(str2)
                                                    : formatMarkdown(str1) //item?.replaceAll('\n', '<br />')
                                            }}
                                        />
                                    </div>
                                )}
                                {!noClap && <div className='mx-2'>{renderSendMail(str1)}</div>}
                            </div>
                        );
                    })}
                </ul>

                {!!regeneratedChoices?.choices?.length && (
                    <div className={cx('flex justify-center', 'my-2')}>
                        {acceptChoiceCta(list, pos, 0, type)}
                    </div>
                )}
            </div>
        );
    };

    const renderChoice = (
        choice: string,
        targetKey: number,
        pos: number,
        type: string,
        str2?: string,
        noClap?: boolean,
        onRegenChange?: (value: string, targetKey: number, pos: number, idx: number) => void
    ) => {
        choice = formatMarkdown(choice);

        return (
            <div className={cx(styles.listContainer, 'pr-4')}>
                <div
                    className={styles.listDataItem}
                    style={{ minHeight: textref?.current?.offsetHeight }}
                >
                    {isEditable ? (
                        renderTextBox(choice, targetKey, pos, 0, type, noClap, onRegenChange)
                    ) : (
                        <div
                            className={TextClass}
                            ref={textref}
                            dangerouslySetInnerHTML={{
                                __html: choice
                            }}
                        />
                    )}
                </div>
                {!!regeneratedChoices?.choices[0]?.length && (
                    <div className={cx('flex justify-center', 'my-2')}>
                        {acceptChoiceCta(str2 ? str2 : choice, pos, 0, type)}
                    </div>
                )}
            </div>
        );
    };

    const renderSingleChoice = (choice: string, targetKey: number, pos: number, type: string) => {
        let regeneratedChoice = regeneratedChoices?.choices[0] || '';

        let str1 = choice;
        let str2 = regeneratedChoice;
        regeneratedChoice = highlightDifferences(str1, regeneratedChoice, styles.diff);
        return (
            <div className={'flex felx-wrap justify-between'}>
                {renderChoice(choice, targetKey, pos, type)}
                {!!regeneratedChoice?.length &&
                    renderChoice(
                        regeneratedChoice,
                        targetKey,
                        pos,
                        type,
                        str2,
                        true,
                        updateRegenChoices
                    )}
                {currentTrends && (
                    <div className={'w-[48%] px-4 flex flex-col justify-between'}>
                        {isEditable ? (
                            <CustomTextArea
                                value={formatMarkdown(currentTrends)}
                                showCount={false}
                                className={styles.listItemContainerWrap}
                                onChange={(value: string) => updateTrends(value)}
                                noClap
                            />
                        ) : (
                            <div
                                className={TextClass}
                                dangerouslySetInnerHTML={{
                                    __html: formatMarkdown(currentTrends)
                                }}
                            />
                        )}

                        <div className={cx('flex justify-center', 'my-2')}>
                            {clearCta()}
                            <CustomButton
                                gradient
                                title={LABELS.MERGE}
                                onClick={() => handleMergeCta(choice, pos, 0, type)}
                            />
                        </div>
                    </div>
                )}
                {renderSendMail(str1)}
            </div>
        );
    };

    const renderSendMail = (data: string) => {
        return <ShareContent data={data} type={choice.type} />;
    };

    return (
        <div className={styles.listData}>
            <div className={styles.listDataTitle}>
                <h1 className={'w-[44%] text-xl'}>{getContentTypeLabel(choice.type)}</h1>
                {/* {renderIcons(!!regeneratedChoices?.choices?.length)} */}
            </div>
            <div className={cx('flex flex-wrap justify-between')}>
                <div className={styles.listDataText}>
                    {choice?.choices.length > 1
                        ? renderBulletList(choice?.choices, targetKey, pos, choice?.type)
                        : renderSingleChoice(choice?.choices[0], targetKey, pos, choice?.type)}
                </div>
            </div>
        </div>
    );
};

export default React.memo(ListData);
