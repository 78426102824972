import React, { useState } from 'react';
import cx from 'classnames';

//widgets
import { CustomButton } from 'src/widgets';
import { toast } from 'react-toastify';
import { Discuss } from 'react-loader-spinner';

//styles
import styles from './BlockGen.module.scss';

//apis
import { useGenerateBlocksMutation } from 'src/redux/queries/Rag';

//assets
import Icomoon from 'src/assets/Icomoon';

interface IProps {
    orgName: string;
    orgId: string;
}

const BlockGen = ({ orgName, orgId }: IProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [blocks, setBlocks] = useState<
        {
            title: string;
            items: string[];
        }[][]
    >([]);

    const [generateBlocks] = useGenerateBlocksMutation();

    const fetchBlocks = async () => {
        try {
            setIsLoading(true);
            await generateBlocks({ orgId })
                .unwrap()
                .then((data) => {
                    setBlocks(() => {
                        return data?.data.map((res: string) => parseDynamicString(res));
                    });
                    toast.success(data?.message);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.data?.message);
                });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowModal = () => {
        document.body.style.overflow = 'hidden';
        setShowModal(true);
        fetchBlocks();
    };

    const handleClose = () => {
        document.body.style.overflow = '';
        setShowModal(false);
    };

    const blockTitles = ['', 'Marketing', 'FAQs'];

    const Blocks = () => {
        return (
            <div className={cx('p-4')}>
                <div className={cx('flex justify-between border-2 font-medium')}>
                    <div className={cx(styles.left, 'border-r-2 p-2')}>{'Blocks'}</div>
                    <div className={cx(styles.right, 'p-2')}>{'Content'}</div>
                </div>
                {blocks?.map((block, key) => {
                    return (
                        <div key={key}>
                            <h1
                                className={cx(
                                    blockTitles[key] && 'p-2 text-center text-xl font-semibold'
                                )}
                            >
                                {blockTitles[key]}
                            </h1>
                            <div>
                                {block.map((section, index) => {
                                    return (
                                        <div
                                            className={cx(
                                                'flex justify-between border-2 border-t-0'
                                            )}
                                            key={index}
                                        >
                                            <div
                                                className={cx(
                                                    styles.left,
                                                    'border-r-2 font-medium p-2',
                                                    {
                                                        [styles.leftFull]: !section?.items?.length
                                                    }
                                                )}
                                            >
                                                {section.title}
                                            </div>
                                            {!!section.items?.length && (
                                                <ul className={cx(styles.right, 'p-2')}>
                                                    {section.items.map((item, itemIndex) => (
                                                        <li key={itemIndex}>{item}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={cx('mx-4')}>
            <CustomButton
                title="Generate Blocks"
                onClick={handleShowModal}
                className={cx('p-1 text-sm font-bold shadow px-4 bg-blue-600 rounded-xl')}
            />

            {showModal && (
                <div className={cx(styles.overlay)}>
                    <div className={cx(styles.container)}>
                        <div className={cx(styles.containerHeading, 'p-4')}>
                            {!isLoading && (
                                <div
                                    className={cx(styles.close, 'rounded-full p-1 cursor-pointer')}
                                >
                                    <Icomoon
                                        className={styles.closeIcon}
                                        icon={'cancel'}
                                        onClick={handleClose}
                                    />
                                </div>
                            )}
                            <div className={cx('text-lg font-medium text-center')}>{orgName}</div>
                            {!isLoading && (
                                <div className={cx(styles.textanimation, 'text-md text-center')}>
                                    {'Generated Blocks'}
                                </div>
                            )}
                        </div>

                        {!!blocks?.length && <Blocks />}

                        {isLoading ? (
                            <div className={cx(styles.loader)}>
                                <Discuss
                                    height={'20%'}
                                    width={'20%'}
                                    colors={['#004170', '#004170']}
                                    ariaLabel="loading"
                                    visible={true}
                                    wrapperClass={styles.loader}
                                />
                                <div className={cx('text-md', styles.typewriter)}>
                                    {'Generating Blocks.............'}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(BlockGen);

const parseDynamicString = (dynamicString: string) => {
    const sections = dynamicString.split('###').filter((section) => section.trim() !== '');
    const parsedData = sections.map((section) => {
        const lines = section.trim().split('\n');
        const title = lines.shift()?.trim() || '';
        const items = lines.map((line) => line.trim());
        return { title, items };
    });

    return parsedData;
};
