import {
    ICON_ACCEPT,
    ICON_BADGE,
    ICON_BUILDING,
    ICON_BULB,
    ICON_CALL,
    ICON_CHART,
    ICON_CHEVRON_UP,
    ICON_CLOCK,
    ICON_COMPARE,
    ICON_COMPUTER,
    ICON_DATABSE,
    ICON_DISCOUNT,
    ICON_EYE_DROPPER,
    ICON_FLAG_CHECKERED,
    ICON_GLOBE,
    ICON_GRATIUDE,
    ICON_HAMMER,
    ICON_HEADLINE,
    ICON_HEADPHONES,
    ICON_HEART,
    ICON_HOURGLASS,
    ICON_ID_CARD,
    ICON_LIGHTENING,
    ICON_LINK,
    ICON_LOCATION,
    ICON_MAN,
    ICON_MOTORWAY,
    ICON_SHIELD,
    ICON_STAR,
    ICON_STUDY,
    ICON_TARGET,
    ICON_TESTIMONIAL,
    ICON_TREND,
    ICON_USERS,
    ICON_VISUAL_EFFECT,
    ICON_WALLET,
    ICON_WARNING
} from 'src/shared/constants/icons';

export const EMOTIONAL_FILTERS = 'Emotional Filters';
export const CONTENT_FILTERS = 'Content Filters';
export const PERSONA_FILTERS = 'Persona Filters';
export const SAVE_AS_PRESET = 'Save as Preset';
export const MY_PRESETS = 'My Presets';
export const APPLY = 'Apply';

export const EMOTIONAL_FILTERS_OPTIONS = [
    { label: 'Trust', value: 'Trust', icon: ICON_SHIELD },
    { label: 'Empathy', value: 'Empathy', icon: ICON_HEART },
    { label: 'Inspiration', value: 'Inspiration', icon: ICON_BULB },
    { label: 'Urgency', value: 'Urgency', icon: ICON_CLOCK },
    { label: 'FOMO', value: 'Fear of Missing Out', icon: ICON_CHART },
    { label: 'Empowerment', value: 'Empowerment', icon: ICON_LIGHTENING },
    { label: 'Pride in Leadership', value: 'Pride in Leadership', icon: ICON_BADGE },
    { label: 'Security', value: 'Security', icon: ICON_SHIELD },
    { label: 'Belonging', value: 'Belonging', icon: ICON_USERS },
    { label: 'Fear of Loss', value: 'Fear of Loss', icon: ICON_WARNING },
    { label: 'Anticipation', value: 'Anticipation', icon: ICON_HOURGLASS },
    { label: 'Gratitude', value: 'Gratitude', icon: ICON_GRATIUDE },
    { label: 'Relief', value: 'Relief', icon: ICON_ACCEPT }
];

export const CONTENT_FILTERS_OPTIONS = [
    { label: 'Punchy Opening', value: 'Punchy Opening', icon: ICON_CHEVRON_UP },
    { label: 'Headline', value: 'Attention-Grabbing Headline', icon: ICON_HEADLINE },
    { label: 'Call-to-Action', value: 'Compelling Call-to-Action', icon: ICON_CALL },
    { label: 'Engaging Storytelling', value: 'Engaging Storytelling', icon: ICON_HEADPHONES },
    { label: 'Data-Driven Insights', value: 'Data-Driven Insights', icon: ICON_DATABSE },
    { label: 'Social Proof', value: 'Social Proof', icon: ICON_GLOBE },
    { label: 'Competitor', value: 'Comparison (competitor and competitive)', icon: ICON_COMPARE },
    { label: 'Exclusive Offer', value: 'Exclusive Offer', icon: ICON_DISCOUNT },
    { label: 'Resource Link', value: 'Resource Link', icon: ICON_LINK },
    { label: 'Testimonials', value: 'Testimonials', icon: ICON_TESTIMONIAL },
    { label: 'Visual Impact', value: 'Visual Impact', icon: ICON_VISUAL_EFFECT },
    { label: 'Market Trends', value: 'Market Trends', icon: ICON_TREND }
];

export const PERSONA_FILTERS_OPTIONS = [
    { label: 'Alma Mater', value: 'Alma Mater', icon: ICON_STUDY },
    { label: 'Years of Experience', value: 'Years of Experience', icon: ICON_CLOCK },
    { label: 'Location', value: 'Location', icon: ICON_LOCATION },
    { label: 'Languages', value: 'Languages', icon: ICON_GLOBE },
    { label: 'Company Size', value: 'Company Size', icon: ICON_BUILDING },
    { label: 'Industry', value: 'Industry', icon: ICON_HAMMER },
    { label: 'Budget', value: 'Budget', icon: ICON_WALLET },
    { label: 'Job Title', value: 'Job Title', icon: ICON_ID_CARD },
    { label: 'Pain Points', value: 'Pain Points', icon: ICON_EYE_DROPPER },
    { label: 'Tech Stack', value: 'Tech Stack', icon: ICON_COMPUTER },
    { label: 'Competitors', value: 'Competitors', icon: ICON_FLAG_CHECKERED },
    { label: 'Buyer Journey Stage', value: 'Buyer Journey Stage', icon: ICON_MOTORWAY },
    { label: 'Decision-Making Role', value: 'Decision-Making Role', icon: ICON_BULB },
    { label: 'Motivations', value: 'Motivations', icon: ICON_STAR },
    { label: 'Job Transitions', value: 'Job Transitions', icon: ICON_MAN },
    { label: 'Sustainability Focus', value: 'Sustainability Focus', icon: ICON_TARGET }
];
